import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post2() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])

  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        Profesia de avocat îl are în centrul atenției pe om, ale cărui drepturi
        și libertăți le apără. Astfel, la înscrierea în barou,,avocatul depune,
        în cadru solemn, următorul jurământ:{" "}
        <strong>
          "Jur să respect şi să apăr Constituţia şi legile ţării, drepturile şi
          libertăţile omului şi să exercit profesia de avocat cu cinste şi
          demnitate. Aşa să-mi ajute Dumnezeu!"
        </strong>
      </Text>
      <Text className="text-post">
        De la bun început,{" "}
        <strong>
          avocatul este și trebuie să rămână în strânsă legătură cu Dumnezeu în
          exercitarea profesiei sale.
        </strong>{" "}
        Virtuțile prevăzute de legea avocaturii -{" "}
        <strong>cinstea și demnitatea</strong> - nu pot exista fără virtuțile
        creștinești - <strong>credința, nădejdea și dragostea.</strong>
      </Text>
      <Text className="text-post">
        Atunci când ai credință, când nu îți pierzi speranța în justiție, când
        manifești dragoste față de client și când profesezi cu cinste și cu
        demnitate, așa cum ai jurat în fața lui Dumnezeu, rezultatele nu pot fi
        decât uimitoare, chiar dacă acestea vin după 23 de ani de luptă pentru
        justiție.Și nu mă refer aici doar la soluția favorabilă clientului prin
        recunoașterea drepturilor sale legale (obținerea unor importante
        despăgubiri cuvenite clientului în acest caz), ci, mă refer, în special,
        la rezultatele care transcend această soluție.
      </Text>

      <Text className="text-post">
        <strong>
          Mă refer la bucuria sufletească a avocatului atunci când primește
          mesajul de mulțumire și de recunoștință din partea clientului său.{" "}
        </strong>
        Iar atunci când{" "}
        <strong>clientul este o supraviețuitoare a Holocaustului</strong>, care,
        după persecuția îndurată în timpul celui de-al doilea Război Mondial,
        s-a luptat zeci de ani pentru a i se recunoaște drepturile legale,{" "}
        <strong>
          mesajul de mulțumire din partea acesteia este cu atât mai prețios.
        </strong>
      </Text>
      <Text className="text-post">
        Atunci când o supraviețuitoare a Holocaustului îți spune ție, avocat,
        care ai trăit până acum mai puțin de jumătate din anii săi,{" "}
        <strong>că i-ai fost un ajutor neprețuit</strong>, nu poți decât să
        mulțumești lui Dumnezeu pentru că ai avut credința, nădejdea și
        dragostea, toate la un loc, manifestate față și pentru client!
      </Text>
      <Text className="text-post" style={{ fontWeight: 600 }}>
        Atunci când după zeci de ani de luptă, clientul își pierde orice
        speranță în justiție, avocatul are îndatorirea morală și legală să lupte
        până la capăt pentru clientul său și să îl încurajeze permanent.
      </Text>
      <Text className="text-post" style={{ fontWeight: 600 }}>
        Despre aceasta este profesia de avocat, căci avocatura este, înainte de
        orice, despre și pentru oameni!
      </Text>
      <Text className="text-post">
        Deviza <strong>Cabinetului de avocat Pau Camelia</strong> a fost și va
        rămâne aceeași, deoarece cred cu tărie că numai{" "}
        <strong>plăcerea muncii de avocat aduce perfecțiunea acesteia</strong>!
        Mulțumesc din suflet, doamna S.A., că m-ați ales să vă fiu avocat și să
        vă apăr drepturile și interesele în justiție!
      </Text>

      <Text className="text-post" style={{ fontWeight: 600 }}>
        VĂ MULȚUMESC PENTRU MESAJUL DE SUFLET, UN DAR NEPREȚUIT PENTRU MINE CA
        AVOCAT!
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => navigate(RoutesOptions.BLOG)}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt={"2rem"}>
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            c="#b99350"
            transform="uppercase"
            w={isTablet ? "100%" : "70%"}
          >
            {t("title_article_2")}
          </Text>
        </Stack>
        {postTextRo}
        <Stack my="lg"></Stack>
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_2")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=pfbid0D38Sm8ZwFoFHgnnGd9pQupxEDeWv7NxtYHf7KoCV5ZNzwoo1Z1zRU9vVxaPNyUQ2l&id=61560842432737"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
