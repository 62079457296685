import { createStyles, rem } from "@mantine/core"
import { maxWidthMobile, maxWidthTablet } from "../../constants"
export const HEADER_HEIGHT = rem(84)
export const useStyles = createStyles((theme) => ({
  inner: {
    width: "100%",
    height: "100%",
    backgroundColor: "#172a33",
    paddingLeft: "3em",
    paddingRight: "3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "100%",

    [`@media ( ${maxWidthTablet})`]: {
      paddingLeft: "2em",
      paddingRight: "2em",
    },
    [`@media ( ${maxWidthMobile})`]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
  },

  header: {
    height: "5.7rem",
    marginBottom: rem(80),
    backgroundColor: "#172a33",
    borderBottom: "1px solid #ced4da24",
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: 5000,
  },

  links: {
    paddingTop: rem(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "0.5em",
  },

  mainLink: {
    textTransform: "uppercase",
    fontFamily: "Barlow",
    fontSize: "small",
    fontWeight: 600,
    letterSpacing: 2,
    cursor: "pointer",
    color: "white",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",
    },
  },

  mainLinkActive: {
    height: "2rem",
    textTransform: "uppercase",
    fontFamily: "Barlow",
    fontSize: "small",
    fontWeight: 600,
    letterSpacing: 2,
    transition: "border-color 100ms ease, color 100ms ease",
    color: "white",
    cursor: "pointer",
    textDecoration: "none",
    borderBottom: "3px solid #BA9451",
    paddingBottom: "1rem",
    "&:hover": {
      textDecoration: "none",
    },

    [`@media ( ${maxWidthTablet})`]: {
      border: "none",
      color: " #BA9451",
      height: "1.5rem",
    },
  },
  linkTo: {
    cursor: "pointer",
    color: "#D3D3D3",
    fontFamily: "Barlow",
    fontSize: "small",
    lineBreak: "anywhere",
  },
  burger: { zIndex: 30000 },
  logo: { objectFit: "contain", cursor: "pointer" },
}))
