import { Stack, Text, Image, Flex } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"
import { useTranslation } from "react-i18next"
import { maxWidthTablet } from "../../../constants"
import { CharacteristicsCard } from "./card"
import { useStyles } from "./introduction-class"

export const IntroductionSection = () => {
  const { classes } = useStyles()
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()

  return (
    <Stack spacing={"3rem"} align="center">
      <Text align="center" className={classes.title}>
        {t("title")}
      </Text>

      <Stack align={"center"} spacing={2}>
        <Image alt="Logo" src="/logo.webp" height={200} width={200} />
        <Text className={classes.logoTitle}>PAU CAMELIA</Text>
        <Text className={classes.logoSubtitle}>{t("subtitle")}</Text>
      </Stack>
      <Flex
        gap={"2rem"}
        w="100%"
        h="100%"
        my="1em"
        align="center"
        justify={"space-around"}
        direction={isTablet ? "column" : "row"}
      >
        <CharacteristicsCard
          title={t("characteristics_card_efficiency")}
          icon="/optimization-blue.webp"
          description={t("characteristics_card_efficiency_content")}
        />
        <CharacteristicsCard
          title={t("characteristics_card_professionalism")}
          icon="/badge-blue.webp"
          description={t("characteristics_card_professionalism_content")}
        />
        <CharacteristicsCard
          title={t("characteristics_card_trust")}
          icon="/dependable-blue.webp"
          description={t("characteristics_card_trust_content")}
        />
      </Flex>
    </Stack>
  )
}
