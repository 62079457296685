import { rem, createStyles } from '@mantine/core'
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthMobileSmall,
  maxWidthTablet,
} from '../../constants'

export const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    paddingTop: `calc(${theme.spacing.md} * 2)`,
    paddingBottom: `calc(${theme.spacing.md} * 2)`,
    backgroundColor: 'transparent',
    borderTop: '1px solid #D3D3D3',
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
    paddingLeft: '3em',
    paddingRight: '3em',

    [`@media ( ${maxWidthLaptopSmall})`]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    [`@media ( ${maxWidthTablet})`]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
    [`@media ( ${maxWidthMobile})`]: {
      paddingLeft: '1em',
      paddingRight: '1em',
    },
  },

  wrapper: {
    width: rem(250),
    [`@media ( ${maxWidthTablet})`]: {
      width: rem(220),
    },
    [`@media ( ${maxWidthMobile})`]: {
      width: rem(160),
    },
    [`@media ( ${maxWidthMobileSmall})`]: {
      width: rem(120),
    },
  },

  link: {
    display: 'block',
    color: '#D9D9D9',
    fontFamily: 'Barlow',
    fontSize: 'medium',
    paddingTop: rem(3),
    paddingBottom: rem(3),
    lineBreak: 'anywhere',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },

    [`@media ( ${maxWidthTablet})`]: {
      fontSize: 'small',
    },
  },

  title: {
    fontSize: 'medium',
    fontWeight: 400,
    fontFamily: `Barlow`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: '#A98A51',
    [`@media ( ${maxWidthTablet})`]: {
      fontSize: 'small',
    },
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,

    [`@media ( ${maxWidthTablet})`]: {
      flexDirection: 'column',
    },
  },
  logo: { cursor: 'pointer' },
}))
