import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { CareerSection } from "../sections/career-section/career-section"
import { ContactSection } from "../sections/contact-section/contact-section"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import { IntroductionSection } from "../sections/introduction-section/introduction-section"
import { TestimonialSection } from "../sections/testimonials-section/testimonial-section"
import { ServicesSection } from "../sections/services-section/services-section"
import i18next from "i18next"

export default function HomePage() {
  const language = localStorage.getItem("i18nextLng")

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])

  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <IntroductionSection />
        <ServicesSection />
        <CareerSection />
        <ContactSection idSection={"contactSection"} />
        <TestimonialSection />
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
