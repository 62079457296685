import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post10() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg" spacing="xs">
      <Text className="text-post">
        Într-o societate întemeiată pe valorile democraţiei şi ale statului de
        drept, AVOCATUL ARE UN ROL ESENŢIAL, se arată expres în lege.
      </Text>
      <Text className="text-post">
        DAR OARE CINE ARE UN ROL ESENȚIAL ÎN VIAȚA AVOCATULUI?
      </Text>
      <Text className="text-post">
        <strong>
          ÎN VIAȚA AVOCATULUI ÎN DEVENIRE UN ROL ESENȚIAL ÎL ARE MAESTRUL.
        </strong>
      </Text>
      <Text className="text-post">
        Conform legii, activitatea unui avocat stagiar poate fi îndrumată numai
        de avocaţi definitivi cu o vechime de cel puţin 6 ani în această
        calitate şi care se bucură de o reputaţie profesională neştirbită.
      </Text>
      <Text className="text-post">
        <strong>MAESTRUL ESTE CEL CARE ÎL FORMEAZĂ PE AVOCAT.</strong>
      </Text>

      <Text className="text-post">
        Maestrul joacă un rol esențial în formarea avocatului, așa încât
        valorile după care se ghidează avocatul maestru în activitatea sa
        profesională sunt, de regulă, și valorile care se imprimă și în viața
        avocatului stagiar și după care și acesta se va ghida în activitatea
        profesională ulterioară în calitate de avocat.
      </Text>

      <Text className="text-post">
        Celor care nu mă cunosc în calitate de avocat de pe vremea când am fost
        și eu avocat stagiar - cu mulți ani în urmă, vă fac cunoștință, cu
        bucurie, cu persoana care m-a format pe mine ca și avocat - un om
        deosebit, cu principii de viață sănătoase, care nu numai că a îndeplinit
        cerințele legale de a mă îndruma ca și avocat stagiar, având o reputație
        profesională neștirbită, dar care, mai presus de orice, A AVUT MULT MAI
        MULTĂ ÎNCREDERE ÎN MINE LA ACEL MOMENT DECÂT AVEAM EU.
      </Text>

      <Text className="text-post">
        Pe vremea când am promovat eu examenul de intrare în profesia de avocat,
        era destul de dificil să îți găsești un maestru cu care să închei un
        contract de colaborare pe perioada stagiaturii și fără un astfel de
        contract de colaborare, nu puteai să devii avocat!
      </Text>
      <Text className="text-post">
        Cel care mi-a dat această șansă de a fi avocat și care a avut încredere
        în mine atunci când a fost cel mai important a fost AVOCAT MOȚEC ȘTEFAN
        - MAESTRUL MEU, cel care este și va rămâne unul din mentorii mei -
        avocatul alături de care am lucrat mai bine de 12 ani de zile.
      </Text>
      <Text className="text-post">
        Ștefan a știut că{" "}
        <i>
          ”cel mai bun mod de a afla dacă poți avea încredere în cineva este să
          ai încredere în el.”
        </i>
      </Text>
      <Text className="text-post">
        Pentru că{" "}
        <i>
          “Un maestru nu este cel care are cei mai mulți studenți, ci cel care
          creează cei mai mulți Maeștri.”
        </i>
      </Text>
      <Text className="text-post">
        De aceea,
        <strong>
          {" "}
          ÎI MULȚUMESC MAESTRULUI MEU - AVOCAT MOȚEC ȘTEFAN CĂ M-A CREAT CA ȘI
          AVOCAT MAESTRU, PENTRU CA, LA RÂNDUL MEU, SĂ POT AVEA UN ROL ESENȚIAL
          ÎN VIAȚA ALTOR AVOCAȚI ÎN DEVENIRE!
        </strong>
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => {
            navigate(RoutesOptions.BLOG)
          }}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt="2rem">
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_10")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_10")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/profile.php?id=61560842432737"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
