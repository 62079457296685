import { useEffect, useState } from "react"
import { UnstyledButton, Menu, Image, createStyles } from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"
import i18next from "i18next"

export interface IDataSelectLanguage {
  value: string
  label: string
  image: string
}

const data = [
  { value: "ro", label: "Română", image: "/romania_square.webp" },
  { value: "en", label: "English", image: "/uk_square.webp" },
]
const useStyles = createStyles(() => ({
  control: {
    width: "4rem",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  dropdown: {
    width: "7rem",
  },
  icon: {
    color: "white",
  },
  label: {
    fontWeight: 500,
    fontFamily: "Barlow",
    fontSize: "medium",
    color: "white",
  },
}))

export const LanguagePicker = () => {
  const [opened, setOpened] = useState(false)
  const language = localStorage.getItem("i18nextLng") as string
  const { classes } = useStyles()
  const [selected, setSelected] = useState<IDataSelectLanguage>(data[0])

  useEffect(() => {
    if (language === "ro") {
      setSelected(data[0])
    } else if (language === "en") {
      setSelected(data[1])
    } else setSelected(data[1])
  }, [language])

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image alt="Logo" src={item.image} width={18} height={18} />}
      onClick={() => {
        setSelected(item)
        i18next.changeLanguage(item.value)
      }}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ))

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      radius="md"
      width="8rem"
      position="bottom-end"
      withinPortal
      zIndex={15000}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Image
            alt="Logo"
            src={selected && selected.image}
            width={22}
            height={22}
          />
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  )
}
