import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post8() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        Cu toții cunoaștem expresia <strong>Timpul înseamnă bani</strong>.
        Această expresie își gășește aplicabilitate practică și în sfera
        juridică, mai ales atunci când apar neînțelegeri și conflicte ce nu se
        pot soluționa pe cale amiabilă, ajungându-se astfel la litigii.
      </Text>
      <Text className="text-post">
        <strong>
          Conflictele sunt mari consumatoare și de timp și de resurse financiare
        </strong>{" "}
        pentru că, de cele mai multe ori, presupun procese în instanță ce
        durează uneori chiar și ani de zile.
      </Text>
      <Text className="text-post">
        De aceea, în ultima perioadă de timp, tot mai mulți oameni apelează la
        metodele alternative de soluționare a litigiilor, precum negocierea,
        concilierea, medierea și arbitrajul. Acest lucru este de bun augur și
        chiar recomand și eu aceste metode alternative clienților mei, îndeosebi
        persoanelor juridice/antreprenorilor ori de câte ori este în beneficiul
        lor.
      </Text>

      <Text className="text-post">
        Însă, chiar și atunci când apelați la astfel de metode, este important
        să conștientizați faptul că este recomandabil să fiți asistați,
        <strong>
          {" "}
          consiliați și/sau îndrumați de un avocat în aceste proceduri
          alternative
        </strong>
        proceselor în instanță.
      </Text>
      <Text className="text-post">
        <strong>
          {" "}
          De ce este important acest lucru? Ca să vă răspund în concret la
          această întrebare, vă dau exemplul recent al unui client.{" "}
        </strong>
      </Text>
      <Text className="text-post">
        În cursul acestui an, am fost angajată într-o speță de o persoană care
        încheiase anterior o convenție arbitrală pentru partajarea casei în care
        locuia - un imobil bun comun. Încercând astfel să soluționeze prin
        arbitraj partajul asupra imobilului bun comun, clientul a ajuns în cele
        din urmă tot la instanța de judecată, deoarece a fost nevoit să
        formuleze acțiune în anulare împotriva hotărârii arbitrale pronunțate în
        procedura de arbitraj.
      </Text>
      <Text className="text-post">
        Convenția arbitrală încheiată de părți pentru a-și împărți imobilul bun
        comun și care a stat la baza hotărârii arbitrale s-a materializat
        într-un înscris sub semnătură privată, adică un înscris semnat doar de
        părțile contractante, nefiind încheiat în forma prevăzută de lege - și
        anume în formă autentică notarială.
      </Text>

      <Text className="text-post">
        Codul de procedură civilă dispune însă foarte clar că:{" "}
        <strong>
          <i>
            {" "}
            „În cazul în care convenţia arbitrală se referă la un litigiu legat
            de transferul dreptului de proprietate şi/sau constituirea altui
            drept real asupra unui bun imobil, convenţia trebuie încheiată în
            formă autentică notarială, sub sancţiunea nulităţii absolute.”
          </i>
        </strong>
      </Text>
      <Text className="text-post">
        Chiar dacă în acest caz a fost nevoie să ajungem până la Înalta Curte de
        Casație și Justiție pentru a se constata în recurs nulitatea absolută a
        convenției arbitrale încheiate, în cele din urmă, conflictul juridic tot
        de către instanța de judecată se va soluționa pentru că partajul bunului
        imobil se va efectua tot în instanță.
      </Text>
      <Text className="text-post">
        Așadar, chiar dacă s-a apelat în acest caz la o metodă alternativă de
        soluționare a litigiului, anume la arbitraj, în cele din urmă, nu s-a
        economisit nici timp, nici bani.
      </Text>
      <Text className="text-post">
        Ba din contră, s-a pierdut și timp și bani, fiind încheiată practic o
        convenție arbitrală nevalabilă și, deci, nulă absolută, s-a parcurs o
        procedura arbitrală care, în concret, nu a ajutat părțile la nimic,
        hotărârea arbitrală fiind anulată de instanța de judecată și s-a ajuns
        în final tot la un proces de partaj în fața instanței, proces care
        presupune alt timp și alți bani.
      </Text>

      <Text className="text-post">
        Pentru că timpul înseamnă bani, concluzia este una singură -{" "}
        <strong>
          {" "}
          mai bine să fii asistat și sfătuit juridic de la început, chiar și la
          o negociere, conciliere, mediere sau arbitraj și să eviți astfel
          procesul judiciar
        </strong>
        , decât să fii asistat și/sau reprezentat juridic în instanță ulterior,
        după ce ai încercat de unul singur, fără succes, una sau mai multe din
        metodele alternative de soluționare a litigiilor, încercând astfel să
        eviți procesul în instanță, dar ajungând în final tot la proces.
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => {
            navigate(RoutesOptions.BLOG)
          }}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt="2rem">
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_8")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_8")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=122131649984361414&id=61560842432737&ref=embed_post"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
