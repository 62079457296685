import { createStyles, rem } from '@mantine/core'
import { maxWidthLaptopSmall, maxWidthTablet, minWidthLaptop } from '../../../constants'
export const ICON_SIZE = rem(75)

export const useStyles = createStyles((theme) => ({
  title: {
    letterSpacing: 8,
    fontSize: '1.5em',
    fontFamily: 'Barlow',
    fontWeight: 400,
    [`@media (max-width: ${minWidthLaptop})`]: {
      fontSize: '2.5em',
    },
    [`@media (max-width: ${maxWidthLaptopSmall})`]: {
      fontSize: '1.2em',
    },
    [`@media (max-width: ${maxWidthTablet})`]: {
      fontSize: '0.5em',
    },
  },
  logoTitle: {
    letterSpacing: 3,
    fontSize: '1.5em',
    fontFamily: 'Barlow',
    fontWeight: 600,
    [`@media (max-width: ${minWidthLaptop})`]: {
      fontSize: '2.5em',
    },
    [`@media (max-width: ${maxWidthLaptopSmall})`]: {
      fontSize: '1.2em',
    },
    [`@media (max-width: ${maxWidthTablet})`]: {
      fontSize: '0.5em',
    },
  },
  logoSubtitle: {
    letterSpacing: 0,
    fontSize: '0.75em',
    fontFamily: 'Nunito',
    fontWeight: 500,
    [`@media (max-width: ${minWidthLaptop})`]: {
      fontSize: '1em',
    },
    [`@media (max-width: ${maxWidthLaptopSmall})`]: {
      fontSize: '0.5em',
    },
    [`@media (max-width: ${maxWidthTablet})`]: {
      fontSize: '0.4em',
    },
  },

  card: {
    cursor: 'pointer',
    border: '3px solid #ba9451',
    backgroundColor: '#EDEDED',
    borderRadius: 'lg',
    position: 'relative',
    overflow: 'visible',
    padding: '1em',
    paddingTop: `calc(${theme.spacing.xl} * 0.2 + ${ICON_SIZE} / 2)`,
  },
  hoverText: {
    animationName: 'example',
    animationDuration: '4s',
  },

  cardInactive: {
    backgroundColor: '#EDEDED',
    borderRadius: 'lg',
    position: 'relative',
    overflow: 'visible',
    padding: '1em',
    paddingTop: `calc(${theme.spacing.xl} * 0.2 + ${ICON_SIZE} / 2)`,
  },

  icon: {
    position: 'absolute',
    zIndex: 1000,
    top: `calc(-${ICON_SIZE} / 1.75)`,
    left: `calc(50% - ${ICON_SIZE} / 2)`,
  },
}))
