import { ThemeIcon, Text, Image, Stack } from "@mantine/core"
import { FunctionComponent } from "react"

export const Feature: FunctionComponent<{
  icon: string
  title: string
}> = (props) => {
  const { icon, title } = props

  return (
    <Stack>
      <Stack
        align={"center"}
        spacing={0}
        h={"4.7em"}
        style={{ justifyContent: "center" }}
      >
        <ThemeIcon variant="light" bg="#e8e7e7" size={60} radius={60}>
          <Image alt="Icon" src={icon} height={40} width={40} />
        </ThemeIcon>
        <Text
          mt="sm"
          mb={7}
          ff="Barlow"
          align="center"
          sx={{ textTransform: "uppercase", justifyContent: "center" }}
        >
          {title}
        </Text>
      </Stack>
    </Stack>
  )
}
