import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Stack, Text, Flex, Button, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import "./posts.scss"
import { useNavigate } from "react-router-dom"

export default function Post1() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])

  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        Hotărârea în <strong>Cauza C. împotriva României</strong> din 30.08.2022
        nu este doar o importantă bornă de referință în ceea privește{" "}
        <strong>
          respectarea drepturilor în cazul hărțuirii la locul de muncă
        </strong>
        , dar este și o <strong>lecție de umanitate dată statului român</strong>{" "}
        de către judecătorii{" "}
        <strong>Curții Europene a Drepturilor Omului – CEDO</strong>.
      </Text>
      <Text className="text-post" style={{ fontWeight: 600 }}>
        <a
          href="https://lege5.ro/Gratuit/geztkmbsg4yts/hotararea-in-cauza-c-impotriva-romaniei-din-30082022#google_vignette"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#D3D3D3" }}
        >
          {" "}
          Hotărârea în Cauza C. împotriva României
        </a>{" "}
        a fost publicată în
        <a
          href="https://lege5.ro/MonitorOficial/mongq3danzygiyq/monitorul-oficial-partea-i-nr-221-17-03-2023?numar=221&d=17-03-2023"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#D3D3D3" }}
        >
          {" "}
          Monitorul Oficial al României, Partea I nr. 221 din 17 martie 2023.
        </a>
      </Text>
      <Text className="text-post">
        <strong>
          Cauza se referă la acuzații de hărțuire sexuală la locul de muncă, ca
          urmare a unei plângeri penale depuse de reclamanta C, femeie de
          serviciu în Gara de Est Timișoara împotriva directorului Gării de Est
          Timișoara, numitul C.P., angajatorul fiind compania feroviară CFR S.A.
        </strong>
      </Text>
      <Text className="text-post">
        În această hotărâre, Curtea Europeană a Drepturilor Omului nu numai că
        apără drepturile fundamentale ale omului, dar ne învață o lecție
        importantă de viață despre cum să{" "}
        <strong>fii OM înainte de a fi profesionist al dreptului.</strong>
      </Text>{" "}
      <Text className="text-post">
        Ce avem de învățat din această hotărâre noi, profesioniștii dreptului,{" "}
        <strong>
          este să nu uităm că, deși analizăm, judecăm sau punem în executare
          fapte și hotărâri, în mijlocul acțiunilor noastre se află OAMENI.
        </strong>
      </Text>
      <Text className="text-post">
        Hotărârea este, de asemenea,{" "}
        <strong>
          o lecție valoroasă despre modul în care redactarea hotărârilor/
          ordonanțelor poate avea un impact emoțional semnificativ asupra
          justițiabililor.
        </strong>
      </Text>{" "}
      <Text className="text-post">
        Așadar, e important ca atunci când consiliem un client, când redactăm o
        cerere de chemare în judecată, când facem apărarea, când administrăm
        probele necesare, când redactăm hotărâri, precum și în orice alte
        asemenea situații ivite în viața profesională în domeniul dreptului.
      </Text>
      <Text className="text-post" style={{ fontWeight: 600 }}>
        SĂ NU UITĂM SĂ FIM OAMENI ȘI SĂ NU UITĂM CĂ ÎN FAȚA NOASTRĂ SE AFLĂ
        OAMENI.
      </Text>
      <Text className="text-post" style={{ fontWeight: 600 }}>
        Extras din Hotărârea în Cauza C. împotriva României:
      </Text>
      <Text className="text-post-italic" style={{ marginTop: 0 }}>
        <strong>
          “Autorităţile nu au luat măsuri active pentru a verifica consecinţele
          pe care acţiunile lui C.P. le-au avut asupra reclamantei.
        </strong>{" "}
        În acest sens, având în vedere relevanţa pe care elementul de intimidare
        sau umilire a victimei o are pentru a stabili existenţa infracţiunii de
        hărţuire sexuală în temeiul dreptului intern (supra, pct. 29),{" "}
        <strong style={{ textDecoration: "underline" }}>
          autorităţile ar fi putut dispune o evaluare psihologică a reclamantei
          în scopul obţinerii unei analize de specialitate a reacţiilor acesteia
          după întâlnirile cu C.P. şi pentru a stabili existenţa unor eventuale
          consecinţe psihologice ale pretinsei hărţuiri
        </strong>{" "}
        (a se vedea, mutatis mutandis, M.G.C. împotriva României, nr. 61.495/11,
        pct. 70, 15 martie 2016).{" "}
        <strong>
          Acestea ar fi putut verifica de asemenea dacă existau motive pentru ca
          reclamanta să formuleze acuzaţii false împotriva lui C.P., întrucât
          acest lucru a fost sugerat de unele dintre declaraţiile martorilor.
        </strong>
      </Text>
      <Text className="text-post-italic">
        Cu toate acestea, Curtea observă că nimic din cele de mai sus nu a fost
        făcut în niciun stadiu al anchetei în speţă (a se vedea, mutatis
        mutandis, I.C. împotriva României, nr. 36.934/08, pct. 54, 24 mai 2016).
      </Text>
      <Text className="text-post-italic">
        <strong>83.</strong> În plus,{" "}
        <strong>
          Curtea observă cu îngrijorare că Ordonanţa parchetului din 22
          octombrie 2019 conţinea o descriere detaliată a insinuărilor făcute de
          C.P. în declaraţiile sale cu privire la viaţa privată a reclamantei şi
          la presupusele motive ale acţiunilor şi acuzaţiilor acesteia
        </strong>{" "}
        (supra, pct. 18). După cum a afirmat reclamanta, acestea ar fi putut fi
        doar o strategie de apărare pentru C.P. (supra, pct. 18-19 şi 56).{" "}
        <strong>
          Deşi este posibil să fi fost necesar ca procurorul să facă referire la
          anumite aspecte ale acestor declaraţii,{" "}
          <span style={{ textDecoration: "underline" }}>
            este dificil de înţeles cărui scop în examinarea infracţiunii au
            servit prin reproducerea lor extinsă în ordonanţa procurorului. Pe
            lângă faptul că au fost grosolane şi lipsite de respect faţă de
            reclamantă, prezenţa lor a stigmatizat-o şi poate fi considerată o
            încălcare a drepturilor acesteia garantate de art. 8 din Convenţie
          </span>
        </strong>{" "}
        (a se vedea, mutatis mutandis, J.L. împotriva Italiei, nr. 5.671/16,
        pct. 136, 27 mai 2021, şi Sanchez Cardenas împotriva Norvegiei, nr.
        12.148/03, pct. 33-39, 4 octombrie 2007).
      </Text>
      <Text className="text-post-italic">
        <strong>84.</strong> În acelaşi sens trebuie observat că, în cursul
        urmăririi penale, reclamanta a fost confruntată cu U.C.{" "}
        <strong style={{ textDecoration: "underline" }}>
          Procurorul nu a oferit nicio explicaţie cu privire la necesitatea
          acestei confruntări şi la impactul acesteia asupra reclamantei.
        </strong>{" "}
        Curtea reiterează faptul că{" "}
        <strong style={{ textDecoration: "underline" }}>
          necesitatea unei confruntări trebuie cântărită cu atenţie de către
          autorităţi şi că trebuie luate în considerare şi protejate demnitatea
          şi sensibilitatea victimei{" "}
        </strong>
        (a se vedea, mutatis mutandis, Y. împotriva Sloveniei, citată anterior,
        pct. 103).
      </Text>
      <Text className="text-post-italic">
        <strong>85.</strong> În această privinţă, Curtea reiterează faptul că,
        pe plan internaţional,{" "}
        <strong>
          hărţuirea sexuală este condamnată fără echivoc şi că statele sunt
          îndemnate să îi sancţioneze în mod efectiv pe făptuitori şi să pună
          astfel capăt impunităţii
        </strong>{" "}
        (supra, pct. 44-46). În acelaşi timp, instrumentele internaţionale, în
        special Carta socială europeană şi Convenţia de la Istanbul, impun
        părţilor contractante să ia măsurile legislative şi de alt gen necesare
        pentru a proteja drepturile şi interesele victimelor (a se vedea supra,
        pct. 41-43, şi, respectiv, pct. 44).{" "}
        <strong>
          Astfel de măsuri implică, inter alia, protecţia împotriva victimizării
          secundare
        </strong>{" "}
        (pentru mai multe detalii, a se vedea, mutatis mutandis, Y. împotriva
        Sloveniei, citată anterior, pct. 104),{" "}
        <strong>
          o obligaţie pe care autorităţile nu au îndeplinit-o în speţă.
        </strong>
      </Text>
      <Text className="text-post-italic">
        <strong>86. </strong>În sfârşit, Curtea observă că, chiar şi după ce
        societatea feroviară a luat cunoştinţă de plângerile privind hărţuirea
        sexuală, reclamanta a continuat să suporte consecinţele sale, întrucât a
        fost în cele din urmă obligată să îşi părăsească locul de muncă (a se
        vedea supra, pct. 5 şi 8 in fine).{" "}
        <strong>
          Aceste elemente care, fără îndoială, i-au sporit suferinţa şi
          sentimentul de neputinţă nu au avut nicio influenţă asupra modului în
          care autorităţile au examinat plângerile sale.
        </strong>
      </Text>
      <Text className="text-post-italic">
        <strong>87.</strong> Pentru aceste motive, fără a depăşi domeniul de
        aplicare al examinării sale şi principiul subsidiarităţii şi fără a se
        pronunţa cu privire la vinovăţia lui C.P., Curtea constată că{" "}
        <strong>
          urmărirea penală efectuată în cauza reclamantei a avut vicii atât de
          semnificative încât constituie o încălcare a obligaţiilor pozitive ale
          statelor în temeiul art. 8 din Convenţie
        </strong>{" "}
        (a se vedea, mutatis mutandis, M.C. împotriva Bulgariei, citată
        anterior, pct. 167, şi Soderman, citată anterior, pct. 90-91).
      </Text>
      <Text className="text-post-italic" style={{ fontWeight: 600 }}>
        88. În consecinţă, a fost încălcată această dispoziţie.
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => {
            navigate(RoutesOptions.BLOG)
          }}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt={"2rem"}>
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            w={isTablet ? "100%" : "70%"}
            fw={600}
            c="#b99350"
            transform="uppercase"
          >
            {t("title_article_1")}
          </Text>
        </Stack>
        {postTextRo}

        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_1")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=pfbid02NyYDjbAFm5BajQw2jzsRHm6Ms9iLexUAnjYVVa9azjvWN379yQefmhMrnHLKi7pal&id=61560842432737"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
