import { Paper, Image, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { maxWidthMobile } from "../../../constants"
import "./card-introduction.scss"

export const CharacteristicsCard: FunctionComponent<{
  title: string
  icon: string
  description: string
}> = (props) => {
  const { title, icon, description } = props
  const isMobile = useMediaQuery(maxWidthMobile)

  return (
    <>
      <Paper radius="lg" className="card-introduction">
        <Stack className="title" align={"center"}>
          <Image
            alt="Icon "
            src={icon}
            height={60}
            width={60}
            className="img"
          />
          <Text>{title}</Text>
        </Stack>

        <Text className="paragraph" px={isMobile ? "md" : "1.5em"}>
          {description}
        </Text>
      </Paper>
    </>
  )
}
