import { createStyles, getStylesRef } from "@mantine/core"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthMobileSmall,
  maxWidthTablet,
} from "../../../constants"

export const useStyles = createStyles(() => ({
  container: {
    justifyItems: "center",
    alignItems: "center",
  },
  inner: {
    [`@media (${maxWidthMobile})`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  background: {
    background:
      "linear-gradient(153deg, rgba(255,255,255,1) 49%, rgba(181,238,186,1) 100%)",
  },
  card: {
    border: "2px solid #926604",
    backdropFilter: "blur( 4px )",
    borderRadius: 30,
    backgroundColor: "#f6f6f6",
    height: "100%",
    width: "100%",
  },
  dots: {
    width: "auto",
    borderRadius: "100% 0% 0% 100% / 100% 100% 0% 0%;",
    backgroundImage:
      "radial-gradient(#c8efcc 1px, transparent 0.3px), radial-gradient(#c3c3c3 0.3px, transparent 0.7px);)",
    backgroundSize: "25px 20px",
    backgroundPosition: "0 0, 8px 8px",
  },
  backgroundCard: {
    border: "1px solid #d5e4d3",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0.1) 45%, rgba(250,250,250,0.3) 88%)",
    backdropFilter: "blur( 4px )",
  },
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
    padding: 0,
    left: "-15px",
    right: "-15px",
    [`@media (${maxWidthTablet})`]: {
      left: "-35px",
      right: "-35px",
    },
    [`@media (${maxWidthMobile})`]: {
      left: "-8px",
      right: "-8px",
    },
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
  control: {
    color: "white",
    backgroundColor: "#ba9451",
    border: "1px solid #ba9451",
  },
  indicators: {
    bottom: "-2rem",
  },
  indicator: {
    backgroundColor: "#ba9451",
    [`@media (${maxWidthMobile})`]: { width: "0.75em" },
  },
  body: {
    textAlign: "left",
    fontFamily: "Barlow",
    fontWeight: 500,
    fontSize: "large",
    color: "#4b5663",
    marginTop: "1rem",
    [`@media (${maxWidthMobile})`]: {
      textAlign: "center",
      fontSize: "medium",
    },
  },
  cite: {
    color: "#535353",
    fontSize: "medium",
    [`@media (${maxWidthMobile})`]: {
      fontSize: "medium",
      textAlign: "center",
    },
  },
  iconQoute: {
    color: "#b99350",
  },
  viewport: {
    [`@media (min-width: 2500px)`]: {
      height: "calc(20vw * 0.9);",
    },
    [`@media (max-width: 2500px)`]: {
      height: "calc(20vw * 1.2);",
    },
    [`@media (max-width: 2000px)`]: {
      height: "calc(20vw);",
    },
    [`@media (max-width: 1800px)`]: {
      height: "calc(25vw * 1.1);",
    },
    [`@media (max-width: 1600px)`]: {
      height: "calc(25vw * 1.3);",
    },
    [`@media (${maxWidthLaptopSmall})`]: {
      height: "calc(25vw * 1.8);",
    },
    [`@media (max-width: 1200px)`]: {
      height: "calc(25vw * 2.2);",
    },
    [`@media (max-width: 1100px)`]: {
      height: "calc(25vw * 2.8);",
    },
    [`@media (${maxWidthTablet})`]: {
      height: "calc(50vw * 1.2);",
    },
    [`@media (max-width: 800px)`]: {
      height: "calc(50vw * 1.5);",
    },
    [`@media (max-width: 700px)`]: {
      height: "calc(50vw * 1.7);",
    },
    [`@media (max-width: 650px)`]: {
      height: "calc(50vw * 1.9);",
    },
    [`@media (${maxWidthMobile})`]: {
      height: "calc(100vw * 1.3);",
    },
    [`@media (max-width: 400px)`]: {
      height: "calc(100vw * 1.5);",
    },
    [`@media (${maxWidthMobileSmall})`]: {
      height: "calc(100vw * 1.8);",
    },
    [`@media (max-width: 350px)`]: {
      height: "calc(100vw * 2.1);",
    },
  },
}))
