import { createStyles, rem } from "@mantine/core"
import { maxWidthTablet } from "../../../constants"
export const ICON_SIZE = rem(90)

export const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: "#eaeaea",
    borderRadius: "lg",
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.md,
    paddingTop: `calc(${theme.spacing.xl} * 1.5 + ${ICON_SIZE} / 2)`,
  },

  icon: {
    position: "absolute",
    top: `calc(-${ICON_SIZE} / 2)`,
    left: `calc(50% - ${ICON_SIZE} / 2)`,
  },
  error: {
    fontSize: "small",
    textAlign: "left",
    marginLeft: 5,
  },
  input: {
    backgroundColor: "rgba(237, 227, 208, 0.23)",
    border: "1px solid #c4ad85",
    borderRadius: 10,
    width: "100%",
    fontFamily: "Barlow",
    fontSize: "medium",
    fontWeight: 400,
    "&::placeholder": {
      color: "#AA8A50",
      fontFamily: "Barlow",
      fontWeight: 400,
      fontSize: "medium",
    },
    "&:focus": {
      borderColor: "#a98a51",
    },
  },
  form: { width: "100%" },
  buttonForm: {
    fontFamily: "Barlow",
    fontSize: "large",
    fontWeight: 400,
    [`@media ( ${maxWidthTablet})`]: {
      fontSize: "medium",
    },
  },
}))
