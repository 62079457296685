import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Flex, Stack, Text, Image, Button } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post4() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        În ultima <strong> perioadă de timp </strong> auzim vorbindu-se tot mai
        mult despre
        <strong> importanța </strong> <strong> echilibrului </strong> în viața
        omului! Tot mai des se propagă ideea de a găsi un{" "}
        <strong> echilibru </strong> în toate aspectele{" "}
        <strong> vieții noastre</strong>.
      </Text>
      <Text className="text-post">
        Și în <strong> viața juridică</strong>, mai ales în domeniul{" "}
        <strong> afacerilor</strong>, <strong> echilibrul </strong>
        trebuie să fie una dintre principalele <strong> ținte </strong> ale{" "}
        <strong> antreprenorilor</strong>, atât în raporturile cu{" "}
        <strong> partenerii de afaceri</strong>, cu <strong> angajații</strong>,
        dar și cu alți
        <strong> colaboratori</strong>.
      </Text>
      <Text className="text-post">
        Însă, de cele mai multe ori <strong> echilibrul </strong> rămâne un{" "}
        <strong> deziderat greu de atins</strong>. Și ne întrebăm adesea de ce
        oare acest <strong> deziderat </strong> este atât de greu de obținut?!
      </Text>{" "}
      <Text className="text-post">
        Din <strong> experiența mea </strong> ca <strong> avocat </strong> în
        domeniul <strong> avocaturii de business</strong>, am constatat că{" "}
        <strong> echilibrul </strong> nu poate fi atins decât dacă înclinăm{" "}
        <strong> balanța </strong>
        și către cealaltă parte, nu numai către{" "}
        <strong> propriul client</strong>, decât dacă avem deschiderea și
        abilitatea de a privi <strong> lucrurile </strong> și prin{" "}
        <strong> ochii </strong>
        celui cu care <strong> negociem </strong> sau cu care{" "}
        <strong> ne judecăm</strong>, decât dacă reușim să ne punem efectiv și
        în <strong> locul celuilalt</strong>, făcând chiar un{" "}
        <strong> schimb fictiv de roluri </strong> pentru a putea identifica mai
        bine <strong> soluția optimă </strong> în fiecare caz.
      </Text>
      <Text className="text-post">
        Una din <strong> preocupările constante </strong> ale{" "}
        <strong> avocatului </strong> care activează în
        <strong> avocatura de business </strong> ar trebui să fie aceea de a
        găsi <strong> echilibrul </strong>
        între <strong> interesele clientului său </strong> și cele ale{" "}
        <strong> co-contractantului </strong> sau ale
        <strong> adversarului clientului să</strong>. Abia atunci{" "}
        <strong> consilierea juridică </strong> este benefică și corespunzătoare{" "}
        <strong> intereselor clientului său </strong> și doar atunci clientul
        poate fi îndrumat spre luarea celei mai bune <strong> decizii </strong>{" "}
        pentru el în fiecare caz în parte!
      </Text>{" "}
      <Text className="text-post">
        Se spune că nici atunci când ai <strong> dreptate</strong>, să nu strici{" "}
        <strong> pacea </strong> pentru
        <strong> dreptate</strong>. Convingerea mea este, însă, aceea că{" "}
        <strong> dreptatea </strong> prin găsirea
        <strong> echilibrului </strong> ar putea aduce și{" "}
        <strong> pacea </strong> și <strong> dreptatea </strong> în același
        timp.
      </Text>
      <Text className="text-post">
        Unul dintre <strong> simbolurile justiției </strong> este{" "}
        <strong> balanța </strong> ce semnifică <strong> dreptatea</strong>, iar{" "}
        <strong> Zeița Dreptății </strong> în{" "}
        <strong> mitologia greacă </strong> - <strong> Themis </strong> - ține
        în mână o<strong> balanță </strong> ale cărei talere se află în{" "}
        <strong> echilibru</strong>.
      </Text>
      <Text className="text-post">
        <strong>
          SĂ NE CAUTĂM DREPTATEA ÎN ECHILIBRU ESTE UNEORI MAI BINE DECÂT A NE
          CĂUTA DREPTATEA ÎN INSTANȚĂ !
        </strong>
      </Text>
      <Text className="text-post">
        <strong>
          PENTRU CĂ DREPTATEA ÎN ECHILIBRU NE POATE ADUCE ȘI PACEA ÎN ACELAȘI
          TIMP!
        </strong>{" "}
        Să găsim echilibrul în viața juridică înseamnă, deci, să gândim spre
        pace, nu spre război, urmărind întotdeauna interesul clientului nostru !
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => navigate(RoutesOptions.BLOG)}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt={"2rem"}>
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_4")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_3")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=122113364060361414&id=61560842432737&ref=embed_post"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
