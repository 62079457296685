import {
  Button,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthMobileSmall,
  maxWidthTablet,
  minWidthLaptop,
  RoutesOptions,
} from "../../../constants"
import { useStyles } from "./career-section-class"

export const CareerSection = () => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isLaptopXl = useMediaQuery(minWidthLaptop)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isMobileS = useMediaQuery(maxWidthMobileSmall)
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const activityYears =
    new Date().getFullYear() - new Date("2009").getFullYear()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container maw="100vw" my="6em" h="auto" p={0}>
      <SimpleGrid
        cols={isTablet ? 1 : 2}
        style={{ justifyItems: "center", alignItems: "center" }}
      >
        <Image
          alt="Main photo."
          src={"/main-photo.webp"}
          height={
            isLaptopXl
              ? "35.125rem"
              : isMobile
              ? "412"
              : isMobileS
              ? "367"
              : "27.625rem"
          }
          width={
            isLaptopXl
              ? "23.75rem"
              : isMobile
              ? "280"
              : isMobileS
              ? "250"
              : "18.75rem"
          }
          classNames={{ image: classes.picture }}
        />

        <Stack align={"center"}>
          <Text size={"xl"} ff="Barlow" my={"md"} fw={400} c="#D3D3D3">
            {t("title_career_section")}
          </Text>
          <Stack spacing={10}>
            <Text
              lineClamp={isLaptopS ? 8 : 12}
              className={classes.textCustomize}
            >
              {t("content_career_section_1")}
              <Text align={isLaptopS ? "center" : "left"} mb="xs" mt="xs">
                {t("content_career_section_2")}
              </Text>
              <Text align={isLaptopS ? "center" : "left"} mb="xs">
                {t("content_career_section_3")}
              </Text>
              <Text align={isLaptopS ? "center" : "left"}>
                {t("paragraph_career_page_1_1")} {activityYears}{" "}
                {t("content_career_section_4")}
              </Text>
            </Text>
          </Stack>

          <Button
            radius="md"
            size={"md"}
            color="golden"
            my="md"
            className={classes.buttonReadMore}
            onClick={() => navigate(RoutesOptions.CAREER)}
          >
            {t("button_read_more")}
          </Button>
        </Stack>
      </SimpleGrid>
    </Container>
  )
}
