import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post5() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        Pe cei care m-au cunoscut ca și avocat, dar și pe cei care încă nu m-au
        cunoscut, vă invit să citiți acest articol care este despre mine, despre{" "}
        <strong> avocatul Pau Camelia - Sabina</strong>, pe scurt, m-aș descrie
        ca fiind un <strong> avocat cu drag de oameni</strong>.
      </Text>
      <Text className="text-post">
        Sunt avocat înscris în <strong> Baroul Timiș</strong>, dețin propriul{" "}
        cabinet de avocatură, fiind{" "}
        <strong> avocat titular la Pau Camelia – Cabinet de avocat</strong>, dar
        ceea ce mă bucură și mă împlinește poate cel mai mult, este faptul că
        sunt și <strong> avocat maestru </strong> și pot dărui din tainele{" "}
        avocaturii și către mai tinerii mei colegi{" "}
        <strong>avocați stagiari</strong>.
      </Text>
      <Text className="text-post">
        Mi-am început <strong>activitatea profesională</strong> în domeniul
        dreptului încă din timpul <strong>studiilor de masterat</strong>,
        respectiv din anul 2006, fiind
        <strong> consultant juridic </strong> în cadrul unui cabinet de
        avocatură. Am susținut și am promovat examenul de intrare în profesia de
        avocat în anul 2009, fiind{" "}
        <strong> avocat înscris în Baroul Timiș</strong>.
      </Text>{" "}
      <Text className="text-post">
        În cariera mea de avocat de până acum, am realizat că sunt o persoană
        care se orientează, în primul rând, către <strong> oameni</strong>, iar
        apoi către
        <strong> rezultatele de obținut</strong> în fiecare caz în parte.
      </Text>
      <Text className="text-post">
        Colegele mele de birou îmi spun adesea că, în discuțiile mele cu
        clienții mai întâi fac <strong> psihologie</strong>, iar abia apoi trec
        la <strong>problemele juridice</strong> propriu-zise. Și da, au
        dreptate, așa și fac, pentru că am convingerea că nu am cum să{" "}
        <strong>îi ajut pe ceilalți decât dacă îi înțeleg</strong> și nu pot să
        îi înțeleg decât dacă <strong>îi ascult cu atenție</strong> și le dau și
        timpul, și răgazul necesare să se deschidă și{" "}
        <strong>să își expună situația</strong> pe de-a întregul, cu tot ce
        implică aceasta -{" "}
        <strong>
          cu sentimente, cu emoții, cu trăiri și cu stări de fapt{" "}
        </strong>
        !
      </Text>{" "}
      <Text className="text-post">
        Doar așa pot desluși situația de fapt expusă de client pentru a o putea
        încadra apoi, cât mai <strong> corect și adecvat</strong>, în drept!
      </Text>
      <Text className="text-post">
        Fiind <strong>orientată </strong>mai degrabă spre{" "}
        <strong> oameni </strong>, mă disting ca și avocat prin
        <strong> bucuria</strong> pe care o resimt în a-i sprijin i pe ceilalți
        - și anume pe clienți în apărarea <strong> libertăţilor </strong>,{" "}
        <strong> drepturilor </strong> şi{" "}
        <strong> intereselor lor legitime</strong>, iar pe{" "}
        <strong> stagiari </strong> în a învăța cât de multe despre nobila
        <strong> profesie de avocat </strong>!
      </Text>
      <Text className="text-post">
        Pentru că la rândul meu, am învățat și eu de la{" "}
        <strong> Numărul 1 Mondial Expert în Leadership </strong> -{" "}
        <strong> John Maxwell </strong>, ceva care se aplică fără doar și poate
        și în avocatură:{" "}
        <strong>
          “You can not become a leader for what you can get, you become a leader
          for what you can give to others!”
        </strong>{" "}
        - Nu poți deveni lider pentru ceea ce obții, ci devii lider pentru ceea
        ce poți dărui celorlalți!
      </Text>
      <Text className="text-post">
        Parafrazând, "You can not become a lawyer for what you can get, you
        become a lawyer for what you can give to others!" -{" "}
        <strong>
          NU POȚI DEVENI AVOCAT PENTRU CEEA CE OBȚII , CI DEVII AVOCAT PENTRU
          CEEA CE POȚI DĂRUI CELORLALȚI!{" "}
        </strong>{" "}
      </Text>
      <Text className="text-post">
        Așadar, în cei <strong> 15 ani de activitate</strong> ca și avocat, am
        profesat și m-am specializat în <strong> avocatura de business</strong>,
        ocupându-mă în activitatea profesională, de sprijinirea și acordarea de{" "}
        <strong> consultanță juridică specializată </strong> în mod preponderent
        persoanelor juridice (<strong> societăți</strong>,
        <strong> instituții publice</strong>, <strong> asociații</strong>,{" "}
        <strong> fundații </strong>) încă de la înființarea acestora, dar și pe
        parcursul activității lor, uneori până la <strong> radiere </strong>
        și <strong> lichidare </strong>.
      </Text>
      <Text className="text-post">
        Dețin și calitatea de <strong> expert achiziții publice</strong>, fiind
        specializată în domeniul <strong> achizițiilor publice </strong> și
        acordând <strong> consultanța juridică </strong>
        necesară în vederea participării cu succes a firmelor la{" "}
        <strong> licitațiile publice</strong>, dar și{" "}
        <strong> reprezentarea juridică</strong> în instanță în{" "}
        <strong> litigiile de achiziții publice</strong>.<br></br>{" "}
      </Text>
      <Text className="text-post">
        <strong>
          DAR CEL MAI IMPORTANT, CU DRAG DE OAMENI FIIND, DĂRUIESC ÎNTODEAUNA
          CELORLALȚI O PARTE DIN MINE - CAMELIA PAU AVOCAT !
        </strong>
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => {
            navigate(RoutesOptions.BLOG)
          }}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt="2rem">
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_5")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_5")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/photo.php?fbid=122117212136361414&set=a.122107997048361414&type=3&ref=embed_post"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
