import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image, List } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post9() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        După cum știm, clienții ajung la avocat îndeosebi atunci când întâmpină
        probleme cu legea, cu procesele pe care le au sau când sunt în diverse
        conflicte sau situații problematice cu alții.
      </Text>
      <Text className="text-post">
        În aceste situații, avocatul este dator să dea clientului sfaturi
        juridice corespunzătoare legii şi să acţioneze numai în limitele legii,
        ale statutului profesiei şi ale codului deontologic al avocaților,
        potrivit <strong>crezului său profesional</strong>.
      </Text>
      <Text className="text-post">
        <strong>
          Crezul profesional al avocatului reprezintă simbolul credinței
          acestuia
        </strong>
        , căci în exercitarea profesiei, avocatul nu poate fi supus niciunei
        restricţii, presiuni, constrângeri sau intimidări din partea
        autorităţilor sau instituţiilor publice ori a altor persoane fizice sau
        persoane juridice.
      </Text>
      <Text className="text-post">
        <strong>
          Libertatea şi independenţa avocatului sunt garantate de lege și așa și
          trebuie să fie pentru că, într-o societate întemeiată pe valorile
          democraţiei şi ale statului de drept, AVOCATUL ARE UN ROL ESENŢIAL.
        </strong>
      </Text>
      <Text className="text-post">
        <strong>ȘI TOTUȘI, ÎNAINTE DE TOATE, AVOCATUL ESTE ȘI EL OM.</strong>
      </Text>

      <Text className="text-post">
        <strong>
          Iar în calitate de OM, ar fi bine să ne întrebăm, noi, avocații ce
          poate face OMUL - AVOCAT în Postul Crăciunului respectând deopotrivă
          legea avocaturii, dar și drepturile și interesele clientului?
        </strong>
      </Text>

      <Text className="text-post">
        Iată 10 <strong>“porunci” </strong>de avut în vedere de către avocați în
        această perioadă de post și nu numai, deoarece unele dintre acestea
        reprezintă chiar obligații legale ale avocatului ce trebuie respectate
        tot timpul:
      </Text>
      <List className="text-post" type="ordered">
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul este obligat să depună toată diligenţa pentru apărarea
          drepturilor, libertăţilor şi intereselor legitime ale clienţilor.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul este obligat să se abţină de la asistarea şi sfătuirea unui
          client în realizarea de către acesta de acte sau fapte ce ar putea
          constitui infracţiuni.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul este îndreptăţit să se retragă imediat şi să renunţe la
          asistarea şi reprezentarea clientului în cazul în care acţiunile şi
          scopurile acestuia, aparent legale la începutul asistenţei şi/sau al
          reprezentării, se dovedesc, pe parcursul acesteia, ca fiind
          infracţionale.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Deși avocatul nu este obligat să presteze servicii pro bono (cu
          excepția cazurilor de asistență judiciară gratuită acordată în
          condițiile legii), Postul Crăciunului este o perioadă bună pentru a
          face bine semenilor noștri și pentru a presta astfel{" "}
          <strong>fără remunerație</strong>
          activități specifice profesiei de avocat în favoarea unor persoane
          aflate în nevoie și nu numai. Așadar,{" "}
          <strong>
            AVOCATUL POATE PRESTA SERVICII AVOCAȚIALE PRO BONO ÎN ACORD CU
            CREZUL SĂU PROFESIONAL ȘI PERSONAL.
          </strong>
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatului îi este interzis să folosească expresii care ar putea leza
          instanţa şi participanţii la proces, atât în şedinţa de judecată, cât
          şi în afara acesteia.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul nu poate asista sau reprezenta părţi cu interese contrare în
          aceeaşi cauză sau în cauze conexe şi nu poate pleda împotriva părţii
          care l-a consultat mai înainte în legătură cu aspectele litigioase
          concrete ale pricinii.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul nu poate fi ascultat ca martor şi nu poate furniza relaţii
          niciunei autorităţi sau persoane cu privire la cauza care i-a fost
          încredinţată, decât dacă are dezlegarea prealabilă, expresă şi scrisă
          din partea tuturor clienţilor săi interesaţi în cauză.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul este obligat să respecte secretul profesional. Divulgarea de
          către avocat, fără drept, a unei informaţii confidenţiale din sfera
          privată a clientului său ori care priveşte un secret operaţional sau
          comercial care i-a fost încredinţat în virtutea aceleiaşi calităţi sau
          de care a putut să ia cunoştinţă în timpul desfăşurării activităţilor
          specifice profesiei constituie infracţiune.
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          Avocatul este obligat să denunțe următoarele infracțiuni:
          <List className="text-post">
            <List.Item style={{ color: "white" }}>
              omor, ucidere din culpă sau altă infracţiune care a avut ca urmare
              moartea unei persoane;
            </List.Item>
            <List.Item style={{ color: "white" }}>
              genocid, infracţiuni contra umanităţii sau infracţiuni de război
              contra persoanelor;
            </List.Item>
            <List.Item style={{ color: "white" }}>
              cele prevăzute de art. 32-38 din Legea nr. 535/2004 privind
              prevenirea şi combaterea terorismului, cu modificările şi
              completările ulterioare.
            </List.Item>
          </List>
        </List.Item>
        <List.Item style={{ color: "white", marginBottom: "15px" }}>
          <strong>
            AVOCATUL ESTE ÎNDREPTĂȚIT SĂ ÎȘI ALOCE TIMP PENTRU EL,
          </strong>{" "}
          respectând, la rândul lui, una din poruncile dumnezeiești:
          <strong>
            {" "}
            <i>
              “Adu-ţi aminte de ziua odihnei, ca să o sfinţeşti. Lucrează şase
              zile şi-ţi fă în acelea toate treburile tale, iar ziua a şaptea
              este odihna … să nu faci în acea zi niciun lucru: nici tu, nici
              fiul tău, nici fiica ta, nici sluga ta, …. nici străinul care
              rămâne la tine”
            </i>
          </strong>
        </List.Item>
      </List>

      <Text className="text-post">
        Să ținem seama, așadar, și noi, avocații, de ceea ce Părintele
        Constantin Necula bine ne îndemna pe toți:
        <strong>
          {" "}
          <i>
            „Dacă nu puteţi posti de mâncare, refuzaţi să vă mâncaţi aproapele.
            Fiţi sinceri cu Dumnezeu. Atât. Curajoşi, sinceri şi limpeziţi de
            nădejde.”
          </i>
        </strong>
      </Text>
      <Text className="text-post">
        <strong>
          ȘI DACĂ MAI FACEM ȘI UN BINE CUIVA PRO BONO, CU ATÂT MAI MULT NE
          APROPRIEM DE MENIREA AVOCATULUI ÎN POSTUL CRĂCIUNULUI.
        </strong>
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => {
            navigate(RoutesOptions.BLOG)
          }}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt="2rem">
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_9")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_9")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/profile.php?id=61560842432737"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
