import { createStyles, getStylesRef, rem } from '@mantine/core'
import { maxWidthMobile, maxWidthMobileMedium, maxWidthTablet } from '../../../constants'
export const ICON_SIZE = rem(90)

export const useStyles = createStyles((theme) => ({
  buttonReadMore: {
    fontFamily: 'Barlow',
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
  },
  textCustomize: {
    fontFamily: 'Barlow',
    fontSize: theme.fontSizes.xs,
    textAlign: 'center',
  },
  picture: {
    borderRadius: '15rem',
    border: '1rem solid #FFFFFF',
  },
  controls: {
    ref: getStylesRef('controls'),
    transition: 'opacity 150ms ease',
    opacity: 0,
    left: -20,
    right: 0,
    width: 'calc(100% + 2em )',

    [`@media (max-width: 800px)`]: { width: 'calc(95% + 2em )', left: -10 },
    [`@media (max-width: 500px)`]: { width: 'calc(90% + 2em )', left: -5 },
  },

  root: {
    '&:hover': {
      [`& .${getStylesRef('controls')}`]: {
        opacity: 1,
      },
    },
  },
  control: {
    color: 'white',
    backgroundColor: '#ba9451',
    border: '1px solid #ba9451',
  },
  indicators: { bottom: '-2rem', color: '#ba9451' },
  indicator: { backgroundColor: '#ba9451', [`@media (${maxWidthMobile})`]: { width: '0.75em' } },
}))
