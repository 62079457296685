import React, { useEffect } from 'react';
import { MiddleContainer } from '../../assets/middle-container';
import { FooterPage } from '../footer/footer';
import { HeaderPage } from '../header/header';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Stack, Text, Image, List } from '@mantine/core';
import { maxWidthTablet, RoutesOptions } from '../../constants';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';

export default function Post7() {
  const language = localStorage.getItem('i18nextLng');
  const isTablet = useMediaQuery(maxWidthTablet);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (language) i18next.changeLanguage(language);
  }, [language]);
  const postTextRo = (
    <Stack my='lg'>
      <Text className='text-post'>
        La fel cum în prima zi de muncă, oricărui angajat i se înmânează
        Regulamentul intern privind organizarea și funcționarea muncii în cadrul
        angajatorului pentru ca acesta să cunoască de la bun început care sunt
        regulile de disciplină la locul de muncă, care îi sunt drepturile și
        care îi sunt și obligațiile în timpul serviciului, tot astfel{' '}
        <strong>
          și elevii, și profesorii, dar și părinții ar trebui să înceapă
          pregătirea pentru noul an școlar, ce va începe peste numai câteva
          zile, cu lecturarea STATUTULUI ELEVULUI, pentru a cunoaște de la bun
          început care sunt noile reguli la școală, care sunt drepturile, dar
          foarte important, și care sunt obligațiile elevilor, dar și ale
          profesorilor în noul an școlar 2024 - 2025.
        </strong>
      </Text>
      <Text className='text-post'>
        În luna august a acestui an, s-a adoptat un nou Statut al elevului prin
        Ordinul Ministerului Educației nr. 5707/2024 privind aprobarea
        Statutului, ordin care a intrat în vigoare la data de 12 august 2024.
      </Text>
      <Text className='text-post'>
        Câți dintre voi, dragi elevi, părinți sau profesori ați citit deja
        Statutul elevului aplicabil în acest an școlar?
      </Text>
      <Text className='text-post'>
        Dacă nu ați făcut-o încă, mai este puțin timp, așa încât vă recomand să
        îl citiți cu atenție pentru ca noul an școlar să decurgă fără probleme,
        exact așa cum vi-l doriți să fie
      </Text>
      <Text className='text-post'>
        Elevii au, așa cum este și normal, îndatoriri, și chiar interdicții
        exprese reglementate în noul Statut al elevului.
      </Text>
      <Text className='text-post'>
        <strong>
          {' '}
          Elevilor le sunt interzise următoarele fapte, dar și altele prevăzute
          expres în Statut:
        </strong>
      </Text>
      <List className='text-post'>
        <List.Item style={{ color: 'white' }}>
          să utilizeze telefoanele mobile sau orice alt echipament de
          comunicaţii electronice în timpul desfăşurării orelor de curs,
          inclusiv în timpul activităţilor educaţionale care se desfăşoară în
          afara unităţilor de învăţământ, cu excepţia utilizării acestora în
          scop educativ, cu acordul cadrului didactic, în timpul pauzelor sau în
          spaţiile autorizate explicit de regulamentul intern al unităţii de
          învăţământ. Pe tot parcursul programului şcolar, telefoanele mobile
          ale elevilor sunt depuse într-un spaţiu sigur, special amenajat în
          fiecare sală de clasă, la care are acces profesorul diriginte sau
          directorul unităţii de învăţământ, iar în lipsa acestora cadrul
          didactic desemnat să efectueze serviciul pe şcoală. Nerespectarea
          acestor prevederi poate duce la preluarea echipamentului de către
          personalul didactic/didactic auxiliar al unităţii de învăţământ în
          vederea restituirii lui la finalizarea orelor de curs din ziua
          respectivă, în mod obligatoriu, către părinţi/reprezentanţi legali ai
          beneficiarilor primari minori sau către elevi, după caz, conform
          regulamentului intern al unităţii de învăţământ. Prevederile nu se
          aplică echipamentelor pe care elevii cu CES sunt autorizaţi să le
          folosească
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          să deţină, să consume sau să comercializeze, în unitatea de
          învăţământ, droguri, băuturi alcoolice, băuturi energizante, substanţe
          etnobotanice sau alte substanţe interzise, toate categoriile de
          produse care conţin tutun sau ţigaretele electronice şi să organizeze
          sau să participe/promoveze participarea la jocuri de noroc
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          să aibă comportamente jignitoare, indecente, de intimidare, de
          discriminare şi să manifeste violenţă în limbaj şi în comportament
          faţă de colegi şi faţă de personalul unităţii de învăţământ
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          să provoace, să instige şi să participe la acte de violenţă în
          unitatea de învăţământ şi în afara ei
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          să utilizeze un limbaj trivial sau invective în perimetrul şcolar
        </List.Item>
      </List>
      <Text className='text-post'>
        În cazul elevilor care în timpul orei de curs manifestă comportamente
        care aduc prejudicii activităţii de predare-învăţare-evaluare, cadrul
        didactic poate decide ca aceştia să desfăşoare activitate în şcoală, în
        timpul orei respective, sub supravegherea obligatorie a unui cadru
        didactic sau a unui cadru didactic auxiliar, într-un spaţiu supravegheat
        video din unitatea de învăţământ stabilit pentru desfăşurarea, de
        regulă, a unor activităţi de tipul lectură suplimentară, completarea de
        fişe de lucru etc. În acest caz, părintele/reprezentantul legal al
        elevului va fi informat în scris/prin mijloace de comunicare
        electronică. Prin excepţie, elevii cu cerinţe educaţionale speciale sunt
        preluaţi pentru a desfăşura activitate cu personal specializat.
      </Text>
      <Text className='text-post'>
        <strong>
          Elevii au, însă, și drepturi conform Statutului elevului
        </strong>
        , drepturi pe care cadrele didactice și unitățile de învățământ trebuie
        să le respecte, dintre care amintim câteva:
      </Text>
      <List className='text-post'>
        <List.Item style={{ color: 'white' }}>
          dreptul de a avea acces la actele şcolare proprii ce stau la baza
          situaţiei şcolare
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          dreptul de a fi informaţi privind notele acordate, înaintea
          consemnării acestora
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          dreptul de a învăţa într-un mediu care sprijină libertatea de
          exprimare fără încălcarea drepturilor şi libertăţilor celorlalţi
          participanţi. Libertatea de exprimare nu poate prejudicia demnitatea,
          onoarea, viaţa particulară a persoanei şi nici dreptul la propria
          imagine. Nu se consideră libertate de exprimare următoarele
          manifestări: comportamentul discriminatoriu de orice fel faţă de
          personalul din unitatea de învăţământ sau faţă de propriii colegi,
          utilizarea invectivelor şi a limbajului trivial, alte manifestări ce
          încalcă normele de moralitate
        </List.Item>
        <List.Item style={{ color: 'white' }}>
          dreptul de a oferi feedback, la finalul anului şcolar, cadrelor
          didactice care predau la clasă, prin fişe anonime sau prin intermediul
          unor chestionare online anonime securizate, elaborate conform
          modelului din anexa care face parte integrantă din prezentul statut,
          în baza unei proceduri stabilite de consiliul de administraţie.
          Rezultatele feedbackului sunt discutate cu profesorul de la clasă la
          începutul anului şcolar următor, în vederea îmbunătăţirii activităţii
          de la clasă
        </List.Item>
      </List>
      <Text className='text-post'>
        <strong>
          {' '}
          Potrivit Statutului, ELEVII AU DREPTUL LA APĂRARE, CONFORM LEGII.
        </strong>
      </Text>
      <Text className='text-post'>
        <strong>
          {' '}
          Așa încât, ori de câte ori la școală vă sunt încălcate drepturile
          legale, nu ezitați nicio clipă să vi le apărați, apelând la ajutor
          specializat de la profesioniști ai dreptului.
        </strong>
      </Text>
      <Text className='text-post'>
        Dar mai țineți minte un lucru general valabil și pentru elevi, și pentru
        părinți, dar și pentru profesori -{' '}
        <strong>
          Libertatea oricărei persoane se termină acolo unde începe libertatea
          alteia.
        </strong>
      </Text>
      <Text className='text-post'>
        <strong>
          NU ABUZAȚI DE DREPTURILE VOASTRE ȘI RESPECTAȚI-VĂ, DEOPOTRIVĂ, ȘI
          OBLIGAȚIILE LEGALE!
        </strong>
      </Text>

      <Text className='text-post'>
        Cum altfel ar putea elevii să își exercite dreptul lor legal de a oferi
        feedback cadrelor didactice care predau la clasă și cum să răspundă la
        întrebări din Fișa de feedback, precum -{' '}
        <strong>
          {' '}
          Profesorul ia în considerare şi respectă Statutul elevului?{' '}
        </strong>
        , dacă ei înșiși nu cunosc și nici nu respectă Statutul elevului!!!
      </Text>
      <Text className='text-post'>
        <strong>
          SĂ NE CUNOAȘTEM DREPTURILE ȘI SĂ NI LE APĂRĂM, DAR SĂ LE RESPECTĂM ȘI
          PE ALE ALTORA, ÎNDEPLININDU-NE ÎN ACELAȘI TIMP ȘI OBLIGAȚIILE, ÎN
          CALITATE DE ELEVI, DE PĂRINȚI, DE PROFESORI!{' '}
        </strong>
      </Text>
      <Text className='text-post'>
        <strong>
          ACEASTA ESTE SCHIMBAREA DE CARE ARE NEVOIE SISTEMUL NOSTRU DE
          INVĂȚĂMÂNT!
        </strong>
      </Text>
    </Stack>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className='btn-back'
          onClick={() => {
            navigate(RoutesOptions.BLOG);
          }}>
          <Image src={'./icon-arrow.png'} height={'1rem'} width={'1rem'} />
        </Button>
        <Stack align={'center'} spacing={0} mb='md' mt='2rem'>
          <Text
            size={isTablet ? 'md' : 'xs'}
            ff='Barlow'
            fw={400}
            c='#D3D3D3'
            transform='uppercase'>
            {t('blog_title')}
          </Text>
          <Text
            size={'xl'}
            ff='Barlow'
            mb='md'
            fw={600}
            transform='uppercase'
            c='#b99350'>
            {t('title_article_7')}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify='space-evenly' direction={isTablet ? 'column' : 'row'}>
          <Text ff='Barlow' size={isTablet ? 'medium' : 'xs'}>
            {t('published')}:{' '}
            <span style={{ fontStyle: 'italic', color: '#b99350' }}>
              {t('date_article_7')}
            </span>{' '}
          </Text>
          <Text ff='Barlow' size={isTablet ? 'medium' : 'xs'}>
            {t('source')}:
            <a
              href='https://www.facebook.com/profile.php?id=61560842432737'
              target='_blank'
              rel='noreferrer'
              style={{
                textDecoration: 'none',
                color: '#b99350',
                fontStyle: 'italic',
              }}>
              <img
                src='/facebook.png'
                width='18px'
                height='18px'
                alt='facebook'
                style={{ marginBottom: '3px', marginLeft: '5px' }}></img>{' '}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  );
}
