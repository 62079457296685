import { createStyles, getStylesRef, rem } from '@mantine/core'
import { maxWidthTablet } from '../../constants'
export const ICON_SIZE = rem(90)

export const useStyles = createStyles((theme) => ({
  textCustomize: {
    fontFamily: 'Barlow',
    fontSize: 'large',
    textAlign: 'left',
    [`@media ( ${maxWidthTablet})`]: {
      fontSize: 'medium',
    },
  },

  feature: {
    position: 'relative',
    paddingTop: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
  },

  content: {
    position: 'relative',
    zIndex: 2,
  },

  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    minHeight: rem(420),
    backgroundImage: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    position: 'relative',
    color: theme.black,
  },

  title: {
    textTransform: 'uppercase',
    color: '#D3D3D3',
    fontSize: '1.5em',
    fontFamily: 'Barlow',
    fontWeight: 400,
    marginBottom: '1em',
  },

  item: {
    backgroundColor: '#e8e7e7',
    borderBottom: 0,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
    overflow: 'hidden',
    marginTop: 0,
  },

  control: {
    fontSize: theme.fontSizes.lg,
    padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
    color: theme.black,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  contentText: {
    paddingLeft: theme.spacing.xl,
    lineHeight: 1.6,
    color: theme.black,
  },

  icon: {
    ref: getStylesRef('icon'),
    marginLeft: theme.spacing.md,
  },

  itemOpened: {
    [`& .${getStylesRef('icon')}`]: {
      transform: 'rotate(45deg)',
    },
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.md,

    [theme.fn.smallerThan('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  labelItemAccordion: {
    fontSize: 'large',
    fontWeight: 500,
    fontFamily: 'Barlow',
    [`@media ( ${maxWidthTablet})`]: {
      fontSize: 'medium',
    },
  },
}))
