export const mockDataReview = [
  {
    content:
      "Colaborez cu succes și de foarte mult timp cu Cabinetul de avocat Pau Camelia. De fiecare dată când solutiile de recuperare au avut o portită de reușită, doamna avocat Pau Camelia a reușit să o fructifice spre câstigul nostru, pe cale legala. Recomand cu căldură acest cabinet în activitatea de recuperare creanțe și întocmirea contractelor care ne-au ferit pe cât posibil de situații nedorite. Mulțumesc pentru suportul juridic acordat cu mult profesionalism.",
    name: "Dicu Elian",
    domain: "elstad.ro",
    url: "https://www.elstad.ro",
  },
  {
    content:
      "Colaborez cu succes și de foarte mult timp cu Cabinetul de avocat Pau Camelia. De fiecare dată când solutiile de recuperare au avut o portită de reușită, doamna avocat Pau Camelia a reușit să o fructifice spre câstigul nostru, pe cale legala. Recomand cu căldură acest cabinet în activitatea de recuperare creanțe și întocmirea contractelor care ne-au feritl pe cât posibil. de situații nedorite. Mulțumesc pentru suportul juridic acordat cu mult profesionalism.",
    name: "Suteanu Iancu",
    domain: "eei.ro",
    url: "https://www.eei.ro/",
  },
  {
    content:
      "Cabinetul de avocat Pau Camelia a devenit pentru noi un partener de încredere cu care colaborăm foarte bine de câțiva ani de zile. Cabinetul ne-a reprezentat cu succes în fața Curții de Arbitraj, în litigiile cu partenerii străni, dar și în fața instanțelor de judecată din România, în litigiile cu partenerii din țară și ne acordă sprijin juridic și consultanța necesară în activitatea curentă a societății, dar și informarea legislativă pentru a fi la zi cu toate modificările legislative care apar și care au impact asupra activității societății noastre.",
    name: "Vegheș Ovidiu",
    domain: "trigo-group.com",
    url: "https://www.trigo-group.com",
  },
  {
    content:
      "Ne exprimăm mulțumirea privind suportul juridic oferit de cabinetul de avocat Pau Camelia pentru că, de fiecare dată, solicitările noastre de servicii juridice pentru orice modificări societare la Registrul Comerțului, pentru redactarea de contracte și alte acte juridice și de consiliere juridică, au fost onorate cu promptitudine și profesionalism, iar întelegerile financiare privind onorariile convenite au fost întodeauna respectate.",
    name: "Măguran Alexandru &  Nicolin Daliborca ",
    domain: "plan-net.ro",
    url: "https://www.plan-net.ro",
  },

  {
    content:
      "Mulțumim Cabinetului de avocat Pau Camelia pentru că ne-a fost alături în activitatea noastră în domeniul horeca, că a fost alături de noi și în perioadele dificile cu care ne-am confruntat în timpul pandemiei Covid când au fost necesare negocieri comerciale cu partenerii noștri, negocieri la care ne-am bazat pe sfaturile bune oferite de avocatul nostru. Pau Camelia - Cabinet de avocat ne-a sprijinit, de asemenea, cu operațiunile de modificare și de prelungire a mărcilor pe care le deținem și ne-a reprezentat cu succes în fața OSIM.",
    name: "Grozăvescu Gabriela & Samoilă Ovidiu ",
    domain: "papasam.ro ",
    url: "https://www.papasam.ro ",
  },

  {
    content:
      "Colaborarea cu cabinetul de avocatură Camelia Pau este una ce depășeste deceniul. Aceasta  întrucât este o colaborare promptă, care a răspuns rapid tot timpul nevoilor asociatiei în educatie pe care o manageriez. Legile și regulile s-au schimbat des în anii de când conduc asociația, dar am găsit tot timpul siguranța unei consilieri informate și cercetate când a fost cazul. Recomand Cabinetul de Avocatură Camelia Pau pentru cei care doresc o colaborare de cursă lungă bazată pe foarte mult fair-play, transparență în comunicare și profesionalism.",
    name: "Szuszmann Emilia",
    domain: "emiliaszuszmann.ro",
    url: "https://www.emiliaszuszmann.ro ",
  },
  {
    content:
      "Recomand cu toată încrederea serviciile cabinetului pentru că am avut parte de profesionalism, seriozitate si promptitudine de fiecare data când am apelat. Începând de la constituire societate, consultanta si reprezentare am beneficiat de fiecare data de o echipa loiala și dedicată, mulțumim pentru sprijinul acordat și pentru sufletul pe care-l depuneți in rezolvarea tuturor situațiilor!",
    name: "Mihaela Guran",
    domain: "pensiuneajojo.ro",
    url: "https://www.pensiuneajojo.ro",
  },
  {
    content:
      "Cu cabinetul de avocatură Camelia Pau avem o colaborare excepțională de peste 3 ani de zile. Cabinetul ne oferă servicii juridice și consultanță pentru afacerea noastră  de o calitate foarte bună, servicii juridice privind reprezentarea societății în diverse litigii, dar și asistență juridică în legătură cu contractele comerciale.",
    name: "Robert Bejinariu",
    domain: "swisshouse.ro",
    url: "https://www.swisshouse.ro",
  },
  {
    content:
      "De fiecare dată când am întâmpinat sau întâmpinăm vreo problemă juridică apelăm cu încredere la cabinetul de avocat Pau Camelia pentru că avem siguranța că interesele societății noastre sunt bine apărate în fiecare caz în parte.  Cabinetul de avocat Pau Camelia ne-a apărat cu succes în litigiile pe care le-am avut cu autoritățile în domeniul protecției consumatorilor și nu numai; în același timp ne asigură constant consultanța juridică de care avem nevoie în activitatea noastră de construcții și dezvoltare imobiliară.",
    name: "Miclea Sorin",
    domain: "apartamente-consorhogar.ro",
    url: "https://www.apartamente-consorhogar.ro",
  },
  {
    content:
      "Cabinetul de avocat Pau Camelia ne-a fost alături de la bun început de când am pus bazele Asociației Moving On Breast Cancer, ne-a ajutat cu înființarea asociației și ne-a sprijinit apoi în activitatea asociației noastre. Apreciem foarte mult faptul că pe lângă susținerea juridică de care beneficiem de fiecare dată când avem nevoie de servicii juridice în activitatea asociației, simțim că avem și susținere umană și empatie din partea doamnei avocat Pau Camelia.",
    name: "Ciotea Mihaela ",
    domain: "boobielove.com",
    url: "https://www.boobielove.com",
  },
]

export const mockDataAboutFooter = {
  title: "DESPRE",
  links: [
    {
      icon: "",
      label: "Acasă",
      link: "/",
    },
    {
      icon: "",
      label: "Cine este Camelia Pau?",
      link: "/despre-camelia-pau",
    },
    {
      icon: "",
      label: "În ce domenii excelează?",
      link: "/domeniu-activitate",
    },
  ],
}
export const mockDataContactFooter = {
  title: "CONTACT",
  links: [
    {
      icon: "/contacting.webp",
      label: "+40 723 341 884",
      link: "tel:+40723-341-884",
    },
    {
      icon: "/message.webp",
      label: "camelia.pau@avocattimisoara.com",
      link: "mailto:camelia.pau@avocattimisoara.com",
    },

    {
      icon: "/adress.webp",
      label: "Giroc, str. Macilor, nr 76, jud. Timiș",
      link: "https://www.google.com/maps/place/Strada+Macilor+76,+Timi%C8%99oara+307221/@45.7209315,21.2451934,15.63z/data=!4m6!3m5!1s0x47455dd0a3aa7f65:0xbcd03efb0049ac60!8m2!3d45.720362!4d21.2512149!16s%2Fg%2F11jrd53pp6?entry=ttu",
    },
    {
      icon: "/adress.webp",
      label:
        "Timișoara, Piața Ionel I.C. Brătianu, nr 1, Birou 3, Parter, jud. Timiș",
      link: "https://www.google.com/maps/search/Pia%C8%9Ba+Ionel+I.C.+Br%C4%83tianu,+nr+1,+Birou+3,+Parter,+jud.+Timi%C8%99/@45.7574533,21.229598,17z/data=!3m1!4b1?entry=ttu",
    },
  ],
}
export const mockDataServicesFooter = {
  title: "DOMENII DE PRACTICĂ",
  links: [
    {
      icon: "",
      label: "Dreptul corporativ ",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Dreptul muncii ",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Achiziții publice",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Dreptul fiscal",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Dreptul civil ",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Contencios administrativ",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Dreptul contravențional",
      link: "/domeniu-activitate",
    },
    {
      icon: "",
      label: "Dreptul comercial ",
      link: "/domeniu-activitate",
    },
  ],
}
