import { Text, Timeline, Box, Image } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useTranslation } from "react-i18next"
import { maxWidthTablet } from "../../constants"
import { useStyles } from "./career-page-class"

export const TimelineCareer = () => {
  const { classes } = useStyles()
  const isTablet = useMediaQuery(maxWidthTablet)
  const presentYear = new Date().getFullYear()
  const sizeText = isTablet ? "medium" : "large"
  const { t } = useTranslation()
  return (
    <Box mx="auto">
      <Timeline
        color={"#D3D3D3"}
        ml="lg"
        classNames={{ itemTitle: classes.title }}
      >
        <Timeline.Item
          title="2001"
          bulletSize={40}
          bullet={
            <Image
              alt="Icon student"
              src="/student.webp"
              height={22}
              width={22}
            />
          }
        >
          <Text color="#D3D3D3" size={sizeText} ff="Barlow">
            {t("content_timeline_1")}
          </Text>
        </Timeline.Item>
        <Timeline.Item
          title="2005"
          bulletSize={40}
          bullet={
            <Image
              alt="Icon graduation"
              src="/graduation-cap.webp"
              height={24}
              width={24}
            />
          }
        >
          <Text color="#D3D3D3" size={sizeText} ff="Barlow" w={"80%"}>
            {t("content_timeline_2")}
          </Text>
        </Timeline.Item>
        <Timeline.Item
          title="2006"
          bulletSize={40}
          bullet={
            <Image
              alt="Icon legal advice"
              src="/legal-advice.webp"
              height={27}
              width={27}
            />
          }
        >
          <Text color="#D3D3D3" size={sizeText} ff="Barlow">
            {t("content_timeline_3")}
          </Text>
        </Timeline.Item>
        <Timeline.Item
          title="2007"
          bulletSize={40}
          bullet={
            <Image
              alt="Icon agreement"
              src="/agreement.webp"
              height={24}
              width={24}
            />
          }
        >
          <Text color="#D3D3D3" size={sizeText} ff="Barlow">
            {t("content_timeline_4")}
          </Text>
        </Timeline.Item>
        <Timeline.Item
          title="2009"
          bulletSize={40}
          bullet={
            <Image
              alt="Icon corporate laws"
              src="/corporate-laws.webp"
              height={25}
              width={25}
            />
          }
        >
          <Text color="#D3D3D3" size={sizeText} ff="Barlow">
            {t("content_timeline_5")}
          </Text>
        </Timeline.Item>
        <Timeline.Item
          title={presentYear}
          bulletSize={40}
          bullet={
            <Image
              alt="Icon scales of justice."
              src="/scales-of-justice.webp"
              height={25}
              width={25}
            />
          }
        >
          <Text
            color="#D3D3D3"
            size={isTablet ? "medium" : "large"}
            ff="Barlow"
          >
            {t("content_timeline_6")}
          </Text>
        </Timeline.Item>
      </Timeline>
    </Box>
  )
}
