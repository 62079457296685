import {
  Paper,
  ThemeIcon,
  Image,
  Stack,
  TextInput,
  Textarea,
  Button,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { maxWidthMobile, minWidthLaptop } from "../../../constants"
import { Logo } from "../../header/logo"
import { ICON_SIZE, useStyles } from "./contact-section-class"
import emailjs from "@emailjs/browser"
import { useEffect, useState } from "react"
import { Notification } from "@mantine/core"
import { IconCheck, IconX } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

export const FormContact = () => {
  const { classes } = useStyles()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopL = useMediaQuery(minWidthLaptop)
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()
  const [messageEmail, setMessageEmail] = useState("")

  const sendEmail = (e: any) => {
    e.preventDefault()

    if (formContact.validate().hasErrors === false) {
      setVisible(true)
      emailjs
        .sendForm(
          "service_laiqqu6",
          "template_ic9mftu",
          e.target,
          "NOI_yoBs1KTN0ymxL"
        )
        .then(
          function (response) {
            formContact.reset()
            setMessageEmail(t("succes_message_emailJs"))
            setVisible(false)
          },
          function (error) {
            setMessageEmail(t("error_message_emailJs"))
            setVisible(false)
          }
        )
    }
  }
  const formContact = useForm({
    initialValues: {
      name: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validate: {
      name: (value) => (value.length > 5 ? null : t("error_message_name")),
      phoneNumber: (value) =>
        /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s\.|-)?([0-9]{3}(\s|\.|-|)){2}$/.test(
          value
        ) && value.length > 0
          ? null
          : t("error_message_phone"),
      email: (value) =>
        /^\S+@\S+$/.test(value) && value.length > 0
          ? null
          : t("error_message_email"),
      message: (value) => (value.length > 10 ? null : t("error_message")),
    },
  })
  useEffect(() => {
    setTimeout(() => setMessageEmail(""), 1500)
  }, [messageEmail])

  return (
    <Paper
      radius="xl"
      mah={"38rem"}
      h={"auto"}
      w={isMobile ? "90%" : "80%"}
      className={classes.card}
      mt={`calc(${ICON_SIZE} / 2)`}
    >
      <ThemeIcon
        className={classes.icon}
        size={ICON_SIZE}
        radius={ICON_SIZE}
        color="#BA9451"
      >
        <Image alt="Logo" src={"/logo.webp"} height={60} width={60} />
      </ThemeIcon>
      <Logo />
      <Stack align="center" px={isLaptopL ? "4em" : "2em"}>
        <form className={classes.form} onSubmit={(e) => sendEmail(e)}>
          <TextInput
            mt="md"
            name="name"
            classNames={{ input: classes.input, error: classes.error }}
            placeholder={t("placeholder_form_name")}
            {...formContact.getInputProps("name")}
          />
          <TextInput
            mt="md"
            classNames={{ input: classes.input, error: classes.error }}
            name="phone_number"
            placeholder={t("placeholder_form_phone")}
            {...formContact.getInputProps("phoneNumber")}
          />
          <TextInput
            mt="md"
            classNames={{ input: classes.input, error: classes.error }}
            name="email"
            placeholder={t("placeholder_form_email")}
            {...formContact.getInputProps("email")}
          />
          <Textarea
            mt="md"
            classNames={{ input: classes.input, error: classes.error }}
            name="message"
            placeholder={t("placeholder_form_message")}
            {...formContact.getInputProps("message")}
          />

          <Button
            type="submit"
            radius="md"
            size="md"
            color="golden"
            my={"lg"}
            className={classes.buttonForm}
            loading={visible}
          >
            {t("button_form_contact")}
          </Button>
        </form>
        {messageEmail.includes("succes") ? (
          <Notification
            icon={<IconCheck size="1.1rem" />}
            color="teal"
            radius="lg"
            ff={"Barlow"}
            bg="#e2e2e2"
            fz={"medium"}
            withCloseButton={false}
          >
            {messageEmail}
          </Notification>
        ) : messageEmail.includes("eroare") ? (
          <Notification
            icon={<IconX size="1.1rem" />}
            color="red"
            radius="lg"
            ff={"Barlow"}
            bg="#e2e2e2"
            fz={"medium"}
            withCloseButton={false}
          >
            {messageEmail}
          </Notification>
        ) : null}
      </Stack>
    </Paper>
  )
}
