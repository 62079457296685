import { createStyles, Divider, Stack, Text } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"
export const useStyles = createStyles((theme) => ({
  label: {
    fontFamily: "Barlow",
    fontSize: 12,
  },
}))
export const Logo = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  return (
    <Stack spacing={0} bg="transparent" align={"center"} mb="0.5em">
      <Text size={24} ff={"Nunito"} fw={800} color={"#162b33"}>
        PAU CAMELIA
      </Text>
      <Divider
        label={t("subtitle_form_contact")}
        labelPosition="center"
        classNames={{ label: classes.label }}
        size={0.1}
        color={"#162b33"}
        m={0}
      />
    </Stack>
  )
}
