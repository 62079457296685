import { createStyles, rem } from "@mantine/core"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
} from "../../constants"
export const ICON_SIZE = rem(90)

export const useStyles = createStyles((theme) => ({
  picture: {
    height: "100%",
    width: "100%",
    borderRadius: "2rem",
    border: "0.5rem solid #FFFFFF",
  },

  textCustomize: {
    fontFamily: "Barlow",
    fontSize: "large",
    textAlign: "center",
    [`@media ( ${maxWidthTablet})`]: {
      fontSize: "medium",
    },
  },
  title: {
    fontFamily: "Barlow",
    fontSize: theme.fontSizes.sm,
    color: "#A98A51",
  },
  grid: {
    justifyItems: "center",
    alignItems: "center",
  },
  root: {
    display: "contents",
  },
  figure: {
    width: "30vw",
    [`@media ( ${minWidthLaptop})`]: {
      width: "20vw",
    },
    [`@media ( ${maxWidthLaptopSmall})`]: {
      width: "35vw",
    },
    [`@media ( ${maxWidthTablet})`]: {
      width: "50vw",
    },
    [`@media ( ${maxWidthMobile})`]: {
      width: "200px",
    },
  },
}))
