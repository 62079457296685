import { Container, SimpleGrid, Text, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import i18next from "i18next"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { MiddleContainer } from "../../assets/middle-container"
import { maxWidthTablet } from "../../constants"

import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import { AccordionServices } from "./accordion-services"
import { Feature } from "./features"
import { useStyles } from "./service-page-class"

export default function ServicesPage() {
  const { classes } = useStyles()
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const language = localStorage.getItem("i18nextLng")

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])

  const mockDataServices = [
    {
      content: t("card_coporate_law_content"),
      title: t("card_coporate_law_title"),
      image: "/cooperation.wepb",
      icon: "/cooperation-icon.webp",
    },

    {
      content: t("card_commercial_law_content"),
      title: t("card_commercial_law_title"),
      image: "/industrial-law.webp",
      icon: "/portion.webp",
    },
    {
      content: t("card_labour_law_content"),
      title: t("card_labour_law_title"),
      image: "/service-law.webp",
      icon: "/mechanic.webp",
    },
    {
      content: t("card_public_procurement_law_content"),
      title: t("card_public_procurement_law_title"),
      image: "/public-agreement.webp",
      icon: "/public-agreement-icon.webp",
    },
    {
      content: t("card_tax_law_content"),
      title: t("card_tax_law_title"),
      image: "/fiscal-law.webp",
      icon: "/fiscal-policy.webp",
    },
    {
      content: t("card_civil_law_content"),
      title: t("card_civil_law_title"),
      image: "/civil.webp",
      icon: "/independence-icon.webp",
    },
    {
      content: t("card_administrative_litigation_content"),
      title: t("card_administrative_litigation_title"),
      image: "/firm-law.webp",
      icon: "/rejected.webp",
    },
    {
      content: t("card_tort_law_content"),
      title: t("card_tort_law_title"),
      image: "/contravention.webp",
      icon: "/penalty.webp",
    },
  ]
  const features = mockDataServices.map((feature, index) => (
    <Feature icon={feature.icon} title={feature.title} key={index} />
  ))
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Text size={"xl"} ff="Barlow" mb={"1em"} fw={400} c="#D3D3D3">
          {t("navbar_btn_services")}
        </Text>
        <Text
          size={isTablet ? "medium" : "large"}
          ff="Barlow"
          align="center"
          mb="xs"
        >
          {t("service_page_paragraph_1")}
        </Text>
        <Text
          size={isTablet ? "medium" : "large"}
          ff="Barlow"
          align="center"
          mb="xs"
        >
          {t("service_page_paragraph_2")}
        </Text>
        <Text
          size={isTablet ? "medium" : "large"}
          ff="Barlow"
          align="center"
          mb="xs"
        >
          {t("service_page_paragraph_3")}
        </Text>
        <SimpleGrid
          mt={60}
          cols={3}
          spacing={50}
          breakpoints={[
            { maxWidth: 1025, cols: 2, spacing: "xl" },
            { maxWidth: 755, cols: 1, spacing: "xl" },
          ]}
        >
          {features}
        </SimpleGrid>

        <div className={classes.wrapper}>
          <Container size="sm" maw={"100%"} pt="5em">
            <Title align="center" className={classes.title}>
              {t("title_accordion")}
            </Title>

            <AccordionServices />
          </Container>
        </div>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
