import { Button, Container, Image, Text } from "@mantine/core"
import { IconArrowRight } from "@tabler/icons-react"
import React, { FunctionComponent } from "react"
import "./card-service.scss"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "@mantine/hooks"
import { maxWidthTablet } from "../../../constants"
import { useTranslation } from "react-i18next"

export const CardService: FunctionComponent<{
  title: string
  content: string
  image?: string
}> = (props) => {
  const { title, content, image } = props
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()

  const navigate = useNavigate()
  return (
    <Container className="main">
      <div className="card">
        <Image alt={image} src={image} />
        <div className="overlay">
          <h2>{title}</h2>
        </div>
        <p>{content}</p>

        <Button
          component="a"
          radius="md"
          size="md"
          color="golden"
          className="button"
          onClick={() => navigate("/domeniu-activitate")}
        >
          <Text size={isTablet ? "medium" : "large"} fw={400} ff="Barlow">
            {t("button_learn_more")}
          </Text>
          <IconArrowRight size={15} />
        </Button>
      </div>
    </Container>
  )
}
