import { createStyles, rem } from '@mantine/core'
import { maxWidthLaptopSmall, maxWidthTablet } from '../../../constants'
export const ICON_SIZE = rem(90)

export const useStyles = createStyles((theme) => ({
  buttonReadMore: {
    fontFamily: 'Barlow',
    fontSize: 'large',
    fontWeight: 400,
    [`@media ( ${maxWidthTablet})`]: {
      fontSize: 'medium',
    },
  },
  textCustomize: {
    fontFamily: 'Barlow',
    fontSize: 'large',
    textAlign: 'left',
    [`@media ( ${maxWidthLaptopSmall})`]: {
      fontSize: 'medium',
      textAlign: 'center',
    },
  },
  picture: {
    borderRadius: '2rem',
    border: '0.5rem solid #e6e5e5',
  },
}))
