import { Text, Image, SimpleGrid, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { maxWidthLaptopSmall } from "../../constants"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import { useStyles } from "./career-page-class"
import { TimelineCareer } from "./timeline"
import "./circle.scss"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

export default function CareerPage() {
  const { classes } = useStyles()
  const isLaptosS = useMediaQuery(maxWidthLaptopSmall)
  const { t } = useTranslation()
  const language = localStorage.getItem("i18nextLng")
  const activityYears =
    new Date().getFullYear() - new Date("2009").getFullYear()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (language) i18next.changeLanguage(language)
  }, [language])

  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <SimpleGrid
          cols={isLaptosS ? 1 : 2}
          className={classes.grid}
          spacing="lg"
        >
          <Image
            alt="Camelia Pau"
            src="/main-photo.webp"
            classNames={{
              image: classes.picture,
              root: classes.root,
              figure: classes.figure,
            }}
            withPlaceholder
          />
          <Stack>
            <Text size={"xl"} ff="Barlow" my={"md"} fw={400} c="#D3D3D3">
              {t("title_timeline_career_page")}
            </Text>
            <TimelineCareer />
          </Stack>
        </SimpleGrid>
        <SimpleGrid
          cols={isLaptosS ? 1 : 2}
          my={"4em"}
          style={{ alignItems: "center" }}
        >
          <Stack>
            <Text size={"xl"} ff="Barlow" my={"md"} fw={400} c="#D3D3D3">
              {t("title_career_page")}
            </Text>
            <Text className={classes.textCustomize}>
              {t("paragraph_career_page_1_1")} {activityYears}{" "}
              {t("paragraph_career_page_1_2")}
            </Text>
            <Text className={classes.textCustomize}>
              {t("paragraph_career_page_2")}
            </Text>
            <Text className={classes.textCustomize}>
              {t("paragraph_career_page_3")}
            </Text>
          </Stack>

          <div className="container">
            <div className="hero-circle">
              <Text className="center-planet">
                {t("title_circle_container")}
              </Text>
              <div className="hero-rotate" id="circle">
                <div className="planet">
                  <Text className="content">
                    {t("content_circle_container_1")}
                  </Text>
                </div>
                <div className="planet">
                  <Text className="content">
                    {t("content_circle_container_2")}
                  </Text>
                </div>
                <div className="planet">
                  <Text className="content">
                    {t("content_circle_container_3")}
                  </Text>
                </div>
                <div className="planet">
                  <Text className="content">
                    {t("content_circle_container_4")}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </SimpleGrid>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
