import { Accordion, ThemeIcon, List, Grid } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconPlus } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"
import { maxWidthTablet } from "../../constants"
import { useStyles } from "./service-page-class"

export const AccordionServices = () => {
  const { classes } = useStyles()
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const heightItemAccordion = "75px"
  return (
    <Accordion
      chevronPosition="right"
      chevronSize={50}
      variant="separated"
      styles={{
        chevron: {
          "&[data-rotate]": {
            transform: "rotate(45deg)",
          },
        },
      }}
      chevron={
        <ThemeIcon radius="xl" color={"#a98a51"} size={32}>
          <IconPlus size="1.05rem" stroke={1.5} />
        </ThemeIcon>
      }
      classNames={{ label: classes.labelItemAccordion }}
    >
      <Grid>
        <Grid.Col span={isTablet ? 12 : 6}>
          <Accordion.Item className={classes.item} value="legal-advice">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_consultations")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              {t("panel_accordion_consultations_1")}
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b>{t("title_list_item_consultations_1")} </b>
                  {t("list_item_consultations_1")}
                </List.Item>
                <List.Item>
                  <b> {t("title_list_item_consultations_2")}</b>{" "}
                  {t("list_item_consultations_2")}
                </List.Item>
              </List>
              {t("panel_accordion_consultations_2")}
              <br />
              {t("panel_accordion_consultations_3")}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item className={classes.item} value="customer-assistance">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_support")}
            </Accordion.Control>
            <Accordion.Panel>
              <List className={classes.textCustomize} w={"95%"}>
                <List.Item>
                  <b>{t("title_list_item_support_1")}</b>
                  {t("list_item_support_1")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_support_2")}</b>
                  {t("list_item_support_2")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_support_3")}</b>
                  {t("list_item_support_3")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_support_4")}</b>
                  {t("list_item_support_4")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_support_5")}</b>
                  {t("list_item_support_5")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_support_6")}</b>
                  {t("list_item_support_6")}
                </List.Item>
              </List>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item
            className={classes.item}
            value="establishment-companies"
          >
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_establishment")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              {t("panel_accordion_establishment_1")}
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b>{t("title_list_item_establishment_1")}</b>
                  {t("list_item_establishment_1")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_establishment_2")}</b>
                  {t("list_item_establishment_2")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_establishment_3")}</b>
                  {t("list_item_establishment_3")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_establishment_4")}</b>
                  {t("list_item_establishment_4")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_establishment_5")}</b>
                  {t("list_item_establishment_5")}
                </List.Item>
              </List>
              {t("panel_accordion_establishment_2:")}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item className={classes.item} value="summations">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_summations")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              {t("panel_accordion_summations_1")}
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b>{t("title_list_item_summations_1")}</b>
                  {t("list_item_summations_1")}
                </List.Item>

                <List.Item>
                  <b>{t("title_list_item_summations_2")}</b>
                  {t("list_item_summations_2")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_summations_3")}</b>
                  {t("list_item_summations_3")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_summations_4")}</b>
                  {t("list_item_summations_4")}
                </List.Item>
              </List>
              {t("panel_accordion_summations_2")}
            </Accordion.Panel>
          </Accordion.Item>
        </Grid.Col>
        <Grid.Col span={isTablet ? 12 : 6}>
          <Accordion.Item className={classes.item} value="trademark">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_trademark")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b>{t("title_list_item_trademark_1")}</b>
                  {t("list_item_trademark_1")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_trademark_2")}</b>
                  {t("list_item_trademark_2")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_trademark_3")}</b>
                  {t("list_item_trademark_3")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_trademark_4")}</b>
                  {t("list_item_trademark_4")}
                </List.Item>
              </List>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item className={classes.item} value="contract-negotiation">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_negotiation")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              {t("panel_accordion_negotiation_1")}
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b> {t("title_list_item_negotiation_1")}</b>
                  {t("list_item_negotiation_1")}
                </List.Item>
                <List.Item>
                  <b> {t("title_list_item_negotiation_2")}</b>
                  {t("list_item_negotiation_2")}
                </List.Item>
                <List.Item>
                  <b> {t("title_list_item_negotiation_3")}</b>
                  {t("list_item_negotiation_3")}
                </List.Item>
              </List>
              {t("panel_accordion_negotiation_2")}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item className={classes.item} value="labor-law">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_labor_law")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              {t("panel_accordion_labor_law_1")}
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b>{t("title_list_item_labor_law_1")} </b>{" "}
                  {t("list_item_labor_law_1")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_labor_law_2")} </b>{" "}
                  {t("list_item_labor_law_2")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_labor_law_3")} </b>{" "}
                  {t("list_item_labor_law_3")}
                </List.Item>
              </List>
              {t("panel_accordion_labor_law_2")}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item className={classes.item} value="public-acquisitions">
            <Accordion.Control h={heightItemAccordion}>
              {t("item_accordion_public_acquisitions")}
            </Accordion.Control>
            <Accordion.Panel className={classes.textCustomize}>
              {t("panel_accordion_public_acquisitions_1")}
              <List ff={"Barlow"} w={"95%"} my={"md"}>
                <List.Item>
                  <b>{t("title_list_item_public_acquisitions_1")}</b>
                  {t("list_item_public_acquisitions_1")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_public_acquisitions_2")}</b>
                  {t("list_item_public_acquisitions_2")}
                </List.Item>
                <List.Item>
                  <b>{t("title_list_item_public_acquisitions_3")}</b>
                  {t("list_item_public_acquisitions_3")}
                </List.Item>

                <List.Item>
                  <b>{t("title_list_item_public_acquisitions_4")}</b>
                  {t("list_item_public_acquisitions_4")}
                </List.Item>
              </List>
              {t("panel_accordion_public_acquisitions_2")}
            </Accordion.Panel>
          </Accordion.Item>
        </Grid.Col>
      </Grid>
    </Accordion>
  )
}
