import React, { useEffect } from 'react';
import { MiddleContainer } from '../../assets/middle-container';
import { FooterPage } from '../footer/footer';
import { HeaderPage } from '../header/header';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Stack, Text, Image } from '@mantine/core';
import { maxWidthTablet, RoutesOptions } from '../../constants';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';

export default function Post6() {
  const language = localStorage.getItem('i18nextLng');
  const isTablet = useMediaQuery(maxWidthTablet);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (language) i18next.changeLanguage(language);
  }, [language]);
  const postTextRo = (
    <Stack my='lg'>
      <Text className='text-post'>
        Pentru ca suntem în luna august, în plină perioadă a concediilor de vară
        pentru cei mai mulți dintre noi, dar și pentru că în activitatea
        profesională, am întâlnit nu o dată, ci de multe ori, situații dintre
        cele mai diverse și controversate la clienții societăți comerciale în
        legătură cu dreptul la concediu de odihnă al angajaților, subiectul
        articolului de față tratează{' '}
        <strong>dreptul la concediu de odihnă al salariaților</strong>, dar nu
        din prisma obligației angajatorului de a acorda acest drept, ci de la
        polul opus, și anume din prisma exercitării acestui drept de către
        salariați.
      </Text>
      <Text className='text-post'>
        Dreptul la concediu de odihnă este reglementat în Codul muncii ca un
        drept intangibil, după cum urmează:
      </Text>
      <Text className='text-post' style={{ fontWeight: 600 }}>
        Dreptul la concediu de odihnă anual plătit este garantat tuturor
        salariaților.
        <br />
        Dreptul la concediu de odihnă anual nu poate forma obiectul vreunei
        cesiuni, renunţări sau limitări.
        <br /> Concediul de odihnă se efectuează în fiecare an.
      </Text>{' '}
      <Text className='text-post'>
        Așadar, dreptul la concediu de odihnă operează în favoarea salariaților,
        iar acestui drept îi corespunde, bineînțeles, obligaţia corelativă a
        angajatorului de a-l respecta.
      </Text>
      <Text className='text-post'>
        Ce se întâmplă însă atunci când salariații, pur și simplu pentru că nu
        doresc, nu își exercită anual acest drept al lor?
      </Text>{' '}
      <Text className='text-post'>
        În perioada post pandemică, de când, în cazul multor firme, activitatea
        lucrativă a început să se desfășoare în regim de telemuncă sau, după
        caz, în regim mixt, am remarcat tendința multora dintre salariați de a
        nu mai uza integral de dreptul lor la concediul anual de odihnă,
        probabil pentru că, lucrând de acasă, nu mai simt nevoia așa de
        stringentă a concediului de odihnă și, în aceste condiții, preferă mai
        degrabă să le fie plătite de către angajator zilele neefectuate din
        concediul de odihnă decât să își ia integral, în natură, concediul de
        odihnă la care au dreptul conform legii.
      </Text>
      <Text className='text-post'>
        Codul muncii spune însă foarte clar că numai în cazul încetării
        contractului individual de muncă este permisă compensarea în bani a
        concediului de odihnă neefectuat.
      </Text>
      <Text className='text-post'>
        Așadar, chiar dacă dreptul la concediul de odihnă nu este obligație a
        salariaților, ci este un drept al lor, acest drept nu poate fi exercitat
        decât așa cum prevede legea - adică trebuie exercitat în fiecare an, în
        natură, nu în bani (cu o singură excepție - cazul încetării contractului
        de muncă).
      </Text>
      <Text className='text-post'>
        De ce? Pur și simplu pentru că ODIHNA ESTE MAI IMPORTANTĂ DECÂT BANII.
      </Text>
      <Text className='text-post'>
        Un alt motiv demn de luat în considerare este că la fel ca orice alt
        drept, și dreptul la concediu de odihnă este supus unor termene de
        prescripție și de decădere. Jurisprudența Curții de Justiție a Uniunii
        Europene este în sensul că prescripția dreptului la concediu nu este
        exclusă, atunci când angajatorul și-a îndeplinit obligațiile de
        informare și de încurajare a efectuării concediului de odihnă de către
        angajat.
      </Text>
      <Text className='text-post'>
        Uzați, dragi salariați, de dreptul vostru la concediul anual de odihnă
        care este plătit cu indemnizația de concediu, pentru că, așa cum
        mașinile au nevoie, din când în când, să urmeze programe de regenerare
        pentru a funcționa optim, la fel și oamenii au nevoie de odihnă în
        fiecare an ca să își recapete capacitatea de muncă și să nu își piardă
        sănătatea.
      </Text>
      <Text className='text-post'>
        Prin urmare, ca să nu ajungeți la burn-out, nu dați odihna pe bani, atât
        pentru că legea nu permite acest lucru decât la încetarea contractului
        individual de muncă, dar și pentru că odihna susține sănătatea, iar
        sănătatea nu poate fi cumpărată cu bani, după cum ar trebui să
        conștientizăm cu toții înainte de a ne pierde cu totul capacitatea de
        muncă!
      </Text>
    </Stack>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className='btn-back'
          onClick={() => {
            navigate(RoutesOptions.BLOG);
          }}>
          <Image src={'./icon-arrow.png'} height={'1rem'} width={'1rem'} />
        </Button>
        <Stack align={'center'} spacing={0} mb='md' mt='2rem'>
          <Text
            size={isTablet ? 'md' : 'xs'}
            ff='Barlow'
            fw={400}
            c='#D3D3D3'
            transform='uppercase'>
            {t('blog_title')}
          </Text>
          <Text
            size={'xl'}
            ff='Barlow'
            mb='md'
            fw={600}
            transform='uppercase'
            c='#b99350'>
            {t('title_article_6')}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify='space-evenly' direction={isTablet ? 'column' : 'row'}>
          <Text ff='Barlow' size={isTablet ? 'medium' : 'xs'}>
            {t('published')}:{' '}
            <span style={{ fontStyle: 'italic', color: '#b99350' }}>
              {t('date_article_6')}
            </span>{' '}
          </Text>
          <Text ff='Barlow' size={isTablet ? 'medium' : 'xs'}>
            {t('source')}:
            <a
              href='https://www.facebook.com/permalink.php?story_fbid=122121077606361414&id=61560842432737&ref=embed_post'
              target='_blank'
              rel='noreferrer'
              style={{
                textDecoration: 'none',
                color: '#b99350',
                fontStyle: 'italic',
              }}>
              <img
                src='/facebook.png'
                width='18px'
                height='18px'
                alt='facebook'
                style={{ marginBottom: '3px', marginLeft: '5px' }}></img>{' '}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  );
}
