import { Carousel } from "@mantine/carousel"
import { Container, Stack, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useRef } from "react"
import Autoplay from "embla-carousel-autoplay"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthTablet,
} from "../../../constants"
import { CardService } from "./card-service"
import { useStyles } from "./services-section-class"
import { useTranslation } from "react-i18next"

export const ServicesSection = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isLaptopsS = useMediaQuery(maxWidthLaptopSmall)
  const { classes } = useStyles()
  const autoplay = useRef(Autoplay({ delay: 3000 }))
  const { t } = useTranslation()
  const mockDataServices = [
    {
      content: t("card_coporate_law_content"),
      title: t("card_coporate_law_title"),
      image: "/cooperation.webp",
      icon: "/cooperation-icon.webp",
    },

    {
      content: t("card_commercial_law_content"),
      title: t("card_commercial_law_title"),
      image: "/industrial-law.webp",
      icon: "/portion.webp",
    },
    {
      content: t("card_labour_law_content"),
      title: t("card_labour_law_title"),
      image: "/service-law.webp",
      icon: "/mechanic.webp",
    },
    {
      content: t("card_public_procurement_law_content"),
      title: t("card_public_procurement_law_title"),
      image: "/public-agreement.webp",
      icon: "/public-agreement-icon.webp",
    },
    {
      content: t("card_tax_law_content"),
      title: t("card_tax_law_title"),
      image: "/fiscal-law.webp",
      icon: "/fiscal-policy.webp",
    },
    {
      content: t("card_civil_law_content"),
      title: t("card_civil_law_title"),
      image: "/civil.webp",
      icon: "/independence-icon.webp",
    },
    {
      content: t("card_administrative_litigation_content"),
      title: t("card_administrative_litigation_title"),
      image: "/firm-law.webp",
      icon: "/rejected.webp",
    },
    {
      content: t("card_tort_law_content"),
      title: t("card_tort_law_title"),
      image: "/contravention.webp",
      icon: "/penalty.webp",
    },
  ]

  const cardService = mockDataServices.map((item, index) => (
    <CardService
      key={index}
      content={item.content}
      title={item.title}
      image={item.image}
    />
  ))

  return (
    <Container maw="100vw" my="4em" h="auto" p={0}>
      <Stack align={"center"}>
        <Text size={"xl"} ff="Barlow" my={"md"} fw={400} c="#D3D3D3">
          {t("title_services_section")}
        </Text>
        <Text size={isTablet ? "medium" : "large"} ff="Barlow" align="center">
          {t("content_services_section_paragraph_1")}
        </Text>
        <Text size={isTablet ? "medium" : "large"} ff="Barlow" align="center">
          {t("content_services_section_paragraph_2")}
        </Text>
        <Carousel
          maw={"100%"}
          height={"auto"}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          classNames={{
            controls: classes.controls,
            root: classes.root,
            control: classes.control,
            indicators: classes.indicators,
            indicator: classes.indicator,
          }}
          slideSize={"33.333333%"}
          breakpoints={[
            { maxWidth: "xs", slideSize: "90%", slideGap: "0.75em" },
            { maxWidth: "sm", slideSize: "50%", slideGap: "2em" },
          ]}
          loop
          slideGap="xs"
          align={"start"}
          slidesToScroll={isMobile ? 1 : isLaptopsS ? 2 : 3}
          mt={20}
          controlsOffset={"-1em"}
          withIndicators
        >
          {cardService}
        </Carousel>
      </Stack>
    </Container>
  )
}
