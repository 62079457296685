import { Card, Stack, Button, Text, Image } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { maxWidthTablet } from "../../constants"

export const CardArticle: FunctionComponent<{
  article: any
}> = ({ article }) => {
  const { photo, date, link, title, content } = article
  const isTablet = useMediaQuery(maxWidthTablet)
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Card radius={"md"}>
      <Card.Section>
        <Image src={photo} width="99%" radius="md" m={"1%"} />
      </Card.Section>
      <Card.Section p="sm">
        <Stack h="100%" pb="3rem">
          <Stack spacing={0}>
            <Text
              align="left"
              size={isTablet ? "sm" : "15px"}
              color="#926604"
              fw={500}
              style={{ fontStyle: "italic" }}
              ff="Barlow"
            >
              {date}
            </Text>
            <Text
              align="left"
              size={isTablet ? "md" : "xs"}
              color="#4b5663"
              fw={600}
              ff="Barlow"
            >
              {title}
            </Text>
            <Text
              align="left"
              size={isTablet ? "sm" : "15px"}
              color="#4b5663"
              fw={400}
              lineClamp={3}
              ff="Barlow"
            >
              {content}
            </Text>
          </Stack>

          <Button
            component="a"
            radius="md"
            size="xs"
            color="golden"
            className="button"
            w="fit-content"
            mt="xs"
            mr="xs"
            style={{ position: "absolute", bottom: "12px", right: "10px" }}
            onClick={() => navigate(link)}
          >
            <Text size={isTablet ? "lg" : "medium"} fw={400} ff="Barlow">
              {t("button_read_more")}
            </Text>
          </Button>
        </Stack>
      </Card.Section>
    </Card>
  )
}
