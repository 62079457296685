import { Container, Stack, Text, Image, Group, SimpleGrid } from "@mantine/core"
import { useMediaQuery, useViewportSize } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import {
  maxWidthMobile,
  maxWidthMobileSmall,
  maxWidthTablet,
} from "../../../constants"
import { FormContact } from "./form-contact"

export const ContactSection: FunctionComponent<{ idSection: string }> = ({
  idSection,
}) => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobileS = useMediaQuery(maxWidthMobileSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const { height, width } = useViewportSize()
  const { t } = useTranslation()
  return (
    <Container maw="100vw" h="auto" my="4em" id={idSection} p={0}>
      <SimpleGrid
        cols={isTablet ? 1 : 2}
        h="100%"
        w="100%"
        style={{
          flexDirection: isTablet ? "column" : "row",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Stack
          mb={isTablet ? "4em" : 0}
          w={"100%"}
          align={isTablet ? "center" : "flex-start"}
          spacing={isTablet ? "md" : 0}
        >
          <Text
            size={"xl"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            w={"100%"}
            align={height < 1000 && width > 1250 ? "left" : "center"}
          >
            {t("title_contact_section")}
          </Text>

          <Text align="left" size={"md"} ff="Barlow" fw={500} my="md">
            {t("subtitle_contact_section")}
          </Text>
          <Stack spacing={"2rem"}>
            <Group pl="xs">
              <Image
                alt="Icon contact."
                src="/contacting.webp"
                height={32}
                width={32}
              />
              <Stack spacing={0} align="start">
                <Text size={"sm"} ff="Barlow" fw={500}>
                  {t("phone")}
                </Text>
                {isMobile ? (
                  <Text
                    size={"medium"}
                    ff="Barlow"
                    fw={400}
                    component="a"
                    href="tel:+40723-341-884"
                  >
                    +40 723 341 884
                  </Text>
                ) : (
                  <Text
                    size={isTablet ? "medium" : "large"}
                    ff="Barlow"
                    fw={400}
                    style={{ cursor: "default" }}
                  >
                    +40 723 341 884
                  </Text>
                )}
              </Stack>
            </Group>
            <Group pl="xs">
              <Image
                alt="Icon message."
                src="/message.webp"
                height={34}
                width={34}
              />
              <Stack
                spacing={0}
                align="start"
                w={isMobileS ? "80%" : "fit-content"}
              >
                <Text size={"sm"} ff="Barlow" fw={500}>
                  {t("mail_adress")}
                </Text>
                <Text
                  size={isTablet ? "medium" : "large"}
                  ff="Barlow"
                  fw={400}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href =
                      "mailto:camelia.pau@avocattimisoara.com")
                  }
                >
                  camelia.pau@avocattimisoara.com
                </Text>
              </Stack>
            </Group>
            <Group pl="xs" align={"start"}>
              <Image
                alt="Icon adress."
                src="/adress.webp"
                height={40}
                width={40}
              />
              <Stack
                spacing={0}
                align="start"
                w={width < 550 ? "65%" : width < 990 ? "fit-content" : "70%"}
              >
                <Text size={"sm"} ff="Barlow" fw={500}>
                  {t("adress")}
                </Text>

                <Text
                  size={isTablet ? "medium" : "large"}
                  ff="Barlow"
                  fw={400}
                  align="left"
                  component="a"
                  target="_blank"
                  href="https://www.google.com/maps/search/Pia%C8%9Ba+Ionel+I.C.+Br%C4%83tianu,+nr+1,+Birou+3,+Parter,+jud.+Timi%C8%99/@45.7574533,21.229598,17z/data=!3m1!4b1?entry=ttu"
                >
                  Timișoara, Piața Ionel I.C. Brătianu, nr 1, Birou 3, Parter,
                  jud. Timiș
                </Text>
                <Text
                  size={isTablet ? "medium" : "large"}
                  ff="Barlow"
                  fw={400}
                  align="left"
                  component="a"
                  target="_blank"
                  href="https://www.google.com/maps/place/Strada+Macilor+76,+Timi%C8%99oara+307221/@45.7209315,21.2451934,15.63z/data=!4m6!3m5!1s0x47455dd0a3aa7f65:0xbcd03efb0049ac60!8m2!3d45.720362!4d21.2512149!16s%2Fg%2F11jrd53pp6?entry=ttu"
                >
                  Giroc, str. Macilor, nr 76, jud. Timiș
                </Text>
              </Stack>
            </Group>
          </Stack>
        </Stack>

        <FormContact />
      </SimpleGrid>
    </Container>
  )
}
