import React, { useEffect, useState } from "react"
import {
  Anchor,
  Group,
  Burger,
  Text,
  Image,
  Container,
  Box,
  Flex,
  Divider,
  Drawer,
  Stack,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useStyles } from "./header-class"
import {
  maxWidthMobileSmall,
  maxWidthTablet,
  RoutesOptions,
} from "../../constants"
import { useNavigate } from "react-router-dom"
import { LanguagePicker } from "../../assets/language-picker"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

export const HeaderPage = () => {
  const [openedMenu, setOpenedMenu] = useState(false)
  const { classes } = useStyles()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobileSmall = useMediaQuery(maxWidthMobileSmall)
  const [active, setActive] = useState(0)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const path = window.location.href.split("/")[4]
    if (path === "despre-camelia-pau") {
      setActive(2)
    } else if (path === "domeniu-activitate") {
      setActive(3)
    } else if (path === "blog") {
      setActive(4)
    } else {
      setActive(1)
    }
  }, [])
  useEffect(() => {
    const language = localStorage.getItem("i18nextLng")
    i18next.changeLanguage(language ? language : "ro")
  }, [active])
  return (
    <header className={classes.header}>
      <Container className={classes.inner}>
        {isTablet ? (
          <>
            <Image
              alt="Logo mobile"
              src={"/logo-mobile.webp"}
              height={60}
              width={90}
              className={classes.logo}
              onClick={() => {
                navigate(RoutesOptions.ROOT)
              }}
            />

            <Burger
              opened={openedMenu}
              onClick={() => setOpenedMenu(!openedMenu)}
              size="sm"
              color="#D3D3D3"
              className={classes.burger}
            />

            <Drawer
              opened={openedMenu}
              onClose={() => setOpenedMenu(false)}
              withCloseButton={false}
              position="top"
              size={"fit-content"}
              zIndex={5000}
              p={0}
              transitionProps={{
                transition: "fade",
                duration: 250,
                timingFunction: "ease-in",
              }}
              styles={{
                content: { backgroundColor: "#162b33" },
                body: { padding: 0 },
              }}
            >
              <Stack spacing={"md"} mb="1rem">
                <Stack align={"center"} spacing={0}>
                  <Flex justify={"end"} w="100%" mt="xs" mr="lg">
                    <LanguagePicker />
                  </Flex>
                  <Image
                    alt="Logo original"
                    src="/logo-original.webp"
                    height={140}
                    width={140}
                  />
                </Stack>
                <Anchor
                  component="button"
                  onClick={() => {
                    navigate(RoutesOptions.ROOT)
                  }}
                  className={
                    active === 1 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_home")}
                </Anchor>
                <Anchor
                  component="button"
                  onClick={() => {
                    navigate(RoutesOptions.CAREER)
                  }}
                  className={
                    active === 2 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_who_is")}
                </Anchor>
                <Anchor
                  component="button"
                  onClick={() => {
                    navigate(RoutesOptions.SERVICES)
                  }}
                  className={
                    active === 3 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_services")}
                </Anchor>
                <Anchor
                  component="button"
                  onClick={() => {
                    navigate(RoutesOptions.BLOG)
                  }}
                  className={
                    active === 4 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_blog")}
                </Anchor>
                <Divider color={"#ced4da24"} />
                <Flex gap={5} direction="row" justify="space-around">
                  <Flex
                    gap={5}
                    align="center"
                    direction={isMobileSmall ? "column" : "row"}
                  >
                    <Image
                      alt="Logo phone"
                      src="/phone.webp"
                      height={15}
                      width={15}
                    />
                    <Text
                      className={classes.linkTo}
                      style={{ cursor: "default" }}
                    >
                      +40 723 341 884
                    </Text>
                  </Flex>
                  <Flex
                    gap={5}
                    align="center"
                    direction={isMobileSmall ? "column" : "row"}
                  >
                    <Image
                      alt="Icon mail"
                      src="/icons-mail.webp"
                      height={15}
                      width={15}
                    />
                    <Text
                      className={classes.linkTo}
                      component="a"
                      target="_blank"
                      href="mailto:camelia.pau@avocattimisoara.com"
                    >
                      camelia.pau@avocattimisoara.com
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Drawer>
          </>
        ) : (
          <>
            <Image
              alt="Logo original"
              src="/logo-original.webp"
              height="auto"
              width={100}
              className={classes.logo}
              onClick={() => {
                navigate(RoutesOptions.ROOT)
              }}
            />
            <Box className={classes.links}>
              <Flex justify="flex-end" gap={"0.6em"}>
                <Group spacing={5}>
                  <Image
                    alt="Icon phone"
                    src="/phone.webp"
                    height={15}
                    width={15}
                  />
                  <Text
                    className={classes.linkTo}
                    style={{ cursor: "default" }}
                  >
                    +40 723 341 884
                  </Text>
                </Group>
                <Group spacing={5}>
                  <Image
                    alt="Icon mail"
                    src="/icons-mail.webp"
                    height={15}
                    width={15}
                  />
                  <Text
                    className={classes.linkTo}
                    onClick={() =>
                      (window.location.href =
                        "mailto:camelia.pau@avocattimisoara.com")
                    }
                  >
                    camelia.pau@avocattimisoara.com
                  </Text>
                </Group>
              </Flex>
              <Flex gap={"0.85em"} justify="flex-end" align="flex-start">
                <Anchor
                  onClick={() => {
                    navigate(RoutesOptions.ROOT)
                  }}
                  className={
                    active === 1 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_home")}
                </Anchor>
                <Anchor
                  onClick={() => {
                    navigate(RoutesOptions.CAREER)
                  }}
                  className={
                    active === 2 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_who_is")}
                </Anchor>
                <Anchor
                  onClick={() => {
                    navigate(RoutesOptions.SERVICES)
                  }}
                  className={
                    active === 3 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_services")}
                </Anchor>
                <Anchor
                  component="button"
                  onClick={() => {
                    navigate(RoutesOptions.BLOG)
                  }}
                  className={
                    active === 4 ? classes.mainLinkActive : classes.mainLink
                  }
                >
                  {t("navbar_btn_blog")}
                </Anchor>
                <LanguagePicker />
              </Flex>
            </Box>
          </>
        )}
      </Container>
    </header>
  )
}
