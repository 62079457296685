import { Container, createStyles } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  middleContainer: {
    marginTop: '6em',
    [theme.fn.largerThan('sm')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '3.5em',
      paddingRight: '3.5em',
    },
    [theme.fn.largerThan('xl')]: {
      paddingLeft: '4.75em',
      paddingRight: '4.75em',
    },
    [theme.fn.largerThan(1800)]: {
      paddingLeft: '6em',
      paddingRight: '6em',
    },
    [theme.fn.largerThan(2000)]: {
      paddingLeft: '10em',
      paddingRight: '10em',
    },
    [theme.fn.largerThan(2300)]: {
      paddingLeft: '15em',
      paddingRight: '15em',
    },
  },
}))

export const MiddleContainer = (props: any) => {
  const { children } = props
  const { classes } = useStyles()
  return (
    <Container fluid className={classes.middleContainer}>
      {children}
    </Container>
  )
}
