import React, { useEffect } from "react"
import { MiddleContainer } from "../../assets/middle-container"
import { FooterPage } from "../footer/footer"
import { HeaderPage } from "../header/header"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { Button, Flex, Stack, Text, Image } from "@mantine/core"
import { maxWidthTablet, RoutesOptions } from "../../constants"
import { useMediaQuery } from "@mantine/hooks"
import { useNavigate } from "react-router-dom"

export default function Post3() {
  const language = localStorage.getItem("i18nextLng")
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (language) i18next.changeLanguage(language)
  }, [language])
  const postTextRo = (
    <Stack my="lg">
      <Text className="text-post">
        <strong> “Oamenii nu mai sunt oameni, sunt brand”</strong> spune un vers
        din melodia „Opriți planeta” - o melodie difuzată pe toate canalele.
        Parafrazând acest vers la lumea afacerilor, putem spune că:{" "}
        <strong> FIRMELE NU MAI SUNT FIRME, SUNT BRAND!</strong>
      </Text>
      <Text className="text-post">
        Pentru că, într-adevăr,
        <strong> brand-ul reprezintă chiar o necesitate</strong> de care din ce
        în ce mai mulți antreprenori sunt tot mai conștienți, mai ales după ce
        multe dintre firme și-au mutat activitatea în mediul online, unele chiar
        complet, începând cu anul 2020 - anul de debut al pandemiei COVID-19.
      </Text>
      <Text className="text-post">
        <strong>
          Branding-ul ajută firmele să se diferențieze de competiție
        </strong>
        , ajută la construirea si dezvoltarea unei baze solide de clienți și
        joacă un rol esențial în strategia de marketing și de vânzări a oricărei
        firme.
      </Text>{" "}
      <Text className="text-post">
        Înainte de orice însă, branding-ul trebuie realizat inteligent, iar
        aceasta presupune, în primul rând, că{" "}
        <strong>BRANDING-UL TREBUIE REALIZAT ÎN ACORD CU LEGEA.</strong>
      </Text>
      <Text className="text-post">
        Îmi amintesc și acum, după ani mulți de practică în avocatură, lecția
        învățată chiar în primul an de facultate, când profesorul de Drept roman
        ne-a predat un principiu de drept esențial - principiul{" "}
        <strong>„Nemo censetur ignorare legem”</strong>, care se traduce astfel:
        <strong>
          „Nimeni nu se poate apăra invocând necunoașterea legii”
        </strong>{" "}
        .
      </Text>{" "}
      <Text className="text-post">
        Care va să zică, orice antreprenor care își dorește să construiască un
        brand pe piață și astfel să fie recunoscut după acest brand și să se
        diferențieze de concurență ar trebui să cunoască cât mai multe despre
        cum se face branding-ul în acord cu legea, deoarece necunoașterea legii
        nu poate fi o scuză pentru nimeni.
      </Text>
      <Text className="text-post">
        Așa că: <br />{" "}
        <strong>- dacă vrei sa afli mai multe despre legal branding,</strong>
        <br />
        <strong>
          - dacă vrei ca investiția făcută în identitatea de brand și în
          strategia de brand să fie una sigură și valoroasă pe termen lung,
        </strong>
        <br />
        <strong>
          - dacă vrei ca brand-ul tău să nu creeze risc de confuzie sau risc de
          asociere cu alte brand-uri de pe piață
        </strong>
        ,
        <br />
        <strong>
          {" "}
          - daca vrei să acționezi legal și/sau să fii protejat
        </strong>{" "}
        în cazul în care unul din competitorii de pe piață îți folosește
        brand-ul sau elemente din acesta, nu uita ca nimeni nu se poate apăra{" "}
        <strong>invocând necunoașterea legii</strong>, așa că{" "}
        <strong>ACTIONEAZĂ INTELIGENT ȘI APELEAZA LA PROFESIONIȘTI</strong>{" "}
        pentru succesul garantat al brand-ului tău și pentru o investiție sigură
        în viitorul firmei tale care te va diferenția de concurență.
      </Text>
      <Text className="text-post">
        <strong> PAU CAMELIA - Cabinet de avocat </strong> vă oferă servicii
        complete în domeniul mărcilor, mărcile reprezentând, fără îndoială,
        active importante ale oricărei firme.
      </Text>
      <Text className="text-post">
        Dintre aceste servicii amintim: <br />
        <strong>
          - înregistrarea cererilor de marcă și/sau efectuarea oricăror
          modificări ale mărcilor deja înregistrate,
        </strong>
        <br />
        <strong>
          {" "}
          - consultanță juridică și de reprezentare a clienților în fața
          Oficiului de Stat pentru Invenții și Mărci în legătură cu cererile de
          marcă,
        </strong>
        <br />{" "}
        <strong>
          - opoziții/contestații la înregistrarea mărcilor în fața Oficiului de
          Stat pentru Invenții și Mărci, <br />- consultanță și reprezentare
          juridică în fața instanțelor în acțiunile judiciare în contrafacerea
          mărcilor sau pentru anularea înregistrării unei mărci, precum și
          pentru contestarea deciziilor OSIM și nu numai.
        </strong>
      </Text>
      <Text className="text-post" style={{ fontWeight: 600 }}>
        NU UITAȚI!!! O marcă legal înregistrată aduce beneficii semnificative
        firmei care o deține și, în același timp, are rolul de a preveni
        situații care pot genera serioase pierderi financiare și pot crea
        prejudicii de imagine firmei în cauză.
      </Text>
    </Stack>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Button
          className="btn-back"
          onClick={() => navigate(RoutesOptions.BLOG)}
        >
          <Image src={"./icon-arrow.png"} height={"1rem"} width={"1rem"} />
        </Button>
        <Stack align={"center"} spacing={0} mb="md" mt={"2rem"}>
          <Text
            size={isTablet ? "md" : "xs"}
            ff="Barlow"
            fw={400}
            c="#D3D3D3"
            transform="uppercase"
          >
            {t("blog_title")}
          </Text>
          <Text
            size={"xl"}
            ff="Barlow"
            mb="md"
            fw={600}
            transform="uppercase"
            c="#b99350"
          >
            {t("title_article_3")}
          </Text>
        </Stack>

        {postTextRo}
        <Flex justify="space-evenly" direction={isTablet ? "column" : "row"}>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("published")}:{" "}
            <span style={{ fontStyle: "italic", color: "#b99350" }}>
              {t("date_article_3")}
            </span>{" "}
          </Text>
          <Text ff="Barlow" size={isTablet ? "medium" : "xs"}>
            {t("source")}:
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=pfbid0SVDY8ne6bmg6ZrX8iMLnti79xyQ531a4QjzPNvcPDkEajs7RuW44Moqy8qJRyRAyl&id=61560842432737"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#b99350",
                fontStyle: "italic",
              }}
            >
              <img
                src="/facebook.png"
                width="18px"
                height="18px"
                alt="facebook"
                style={{ marginBottom: "3px", marginLeft: "5px" }}
              ></img>{" "}
              Avocat Camelia Pau
            </a>
          </Text>
        </Flex>
      </MiddleContainer>
      <FooterPage />
    </>
  )
}
