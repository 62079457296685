import { Carousel } from "@mantine/carousel"
import { Stack, Text, Card, Blockquote, Anchor } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import Autoplay from "embla-carousel-autoplay"
import React, { useRef } from "react"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthTablet,
} from "../../../constants"
import { useStyles } from "./testimonial-section-classes"
import { useTranslation } from "react-i18next"

export const TestimonialSection = () => {
  const { classes } = useStyles()
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const autoplay = useRef(Autoplay({ delay: 3000 }))
  const { t } = useTranslation()

  const mockDataReview = [
    {
      content: t("review_elstad"),
      name: "Dicu Elian",
      domain: "elstad.ro",
      url: "https://www.elstad.ro",
    },
    {
      content: t("review_eei"),
      name: "Suteanu Iancu",
      domain: "eei.ro",
      url: "https://www.eei.ro/",
    },
    {
      content: t("review_trigo"),
      name: "Vegheș Ovidiu",
      domain: "trigo-group.com",
      url: "https://www.trigo-group.com",
    },
    {
      content: t("review_plan_net"),
      name: "Măguran Alexandru &  Nicolin Daliborca ",
      domain: "plan-net.ro",
      url: "https://www.plan-net.ro",
    },

    {
      content: t("review_papasam"),
      name: "Grozăvescu Gabriela & Samoilă Ovidiu ",
      domain: "papasam.ro ",
      url: "https://www.papasam.ro ",
    },

    {
      content: t("review_emiliaszuszmann"),
      name: "Szuszmann Emilia",
      domain: "emiliaszuszmann.ro",
      url: "https://www.emiliaszuszmann.ro ",
    },
    {
      content: t("review_pensiuneajojo"),
      name: "Mihaela Guran",
      domain: "pensiuneajojo.ro",
      url: "https://www.pensiuneajojo.ro",
    },
    {
      content: t("review_swiss_house"),
      name: "Robert Bejinariu",
      domain: "swisshouse.ro",
      url: "https://www.swisshouse.ro",
    },
    {
      content: t("review_consorhogar"),
      name: "Miclea Sorin",
      domain: "apartamente-consorhogar.ro",
      url: "https://www.apartamente-consorhogar.ro",
    },
    {
      content: t("review_boobielove"),
      name: "Ciotea Mihaela ",
      domain: "boobielove.com",
      url: "https://www.boobielove.com",
    },
  ]

  const slides = mockDataReview.map((card, index) => {
    return (
      <Carousel.Slide key={index} mx={"0.6em"}>
        <Card radius={"lg"} key={index} className={classes.card}>
          <Card.Section p={"md"} mt={"1em"} h="-webkit-fill-available">
            <Stack
              align="left"
              justify={"center"}
              spacing="0"
              h={"100%"}
              px={isMobile ? 0 : "1rem"}
            >
              <Blockquote
                cite={`- ${card.name} -`}
                p={0}
                classNames={{
                  body: classes.body,
                  inner: classes.inner,
                  cite: classes.cite,
                  icon: classes.iconQoute,
                }}
              >
                {card.content}
              </Blockquote>
              <Anchor
                href={card.url}
                target="_blank"
                ml={isMobile ? 0 : "3rem"}
                size={"medium"}
                color="#926604"
                align={isMobile ? "center" : "left"}
                fw={500}
                fs="italic"
              >
                {card.domain}
              </Anchor>
            </Stack>
          </Card.Section>
        </Card>
      </Carousel.Slide>
    )
  })
  return (
    <Stack align={"center"} my={"6em"}>
      <Text size={"xl"} ff="Barlow" fw={400} c="#D3D3D3">
        {t("title_testimonial_section")}
      </Text>

      <Carousel
        maw={"100%"}
        w="auto"
        mx="auto"
        height={"100%"}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        classNames={{
          controls: classes.controls,
          root: classes.root,
          control: classes.control,
          indicators: classes.indicators,
          indicator: classes.indicator,
          viewport: classes.viewport,
        }}
        loop
        slideSize={"47%"}
        breakpoints={[
          { maxWidth: "xs", slideSize: "87%", slideGap: "xs" },
          { maxWidth: "md", slideSize: "70%", slideGap: "xs" },
        ]}
        slideGap="xs"
        align={slides.length > 0 ? "center" : "start"}
        slidesToScroll={isTablet ? 1 : 2}
        mt={20}
        controlsOffset={isTablet ? "xs" : isLaptopS ? "md" : "xl"}
        withIndicators
      >
        {slides}
      </Carousel>
    </Stack>
  )
}
