import {
  Container,
  Text,
  Image,
  Flex,
  Stack,
  Grid,
  SimpleGrid,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../constants"
import { mockDataContactFooter } from "../../mockdata"
import { useStyles } from "./footer-class"

export const FooterPage = () => {
  const { classes } = useStyles()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const mockDataServicesFooter = {
    title: t("footer_title_services"),
    links: [
      {
        icon: "",
        label: t("card_coporate_law_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_labour_law_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_public_procurement_law_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_tax_law_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_civil_law_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_administrative_litigation_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_tort_law_title"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("card_commercial_law_title"),
        link: "/domeniu-activitate",
      },
    ],
  }
  const mockDataAboutFooter = {
    title: t("footer_title_about"),
    links: [
      {
        icon: "",
        label: t("navbar_btn_home"),
        link: "/",
      },
      {
        icon: "",
        label: t("navbar_btn_who_is"),
        link: "/despre-camelia-pau",
      },
      {
        icon: "",
        label: t("navbar_btn_services"),
        link: "/domeniu-activitate",
      },
      {
        icon: "",
        label: t("navbar_btn_blog"),
        link: "/blog",
      },
    ],
  }
  const groupContact = mockDataContactFooter.links.map((link, index) => (
    <Flex
      align={"center"}
      direction={isTablet ? "column" : "row"}
      gap={isTablet ? 0 : "md"}
      my="xs"
      key={index}
    >
      <Image alt="Icon" src={link.icon} height={20} width={20} />
      {link.label.includes("+40 723 341 884") ? (
        isMobile ? (
          <Text
            className={classes.link}
            align={isTablet ? "center" : "left"}
            component="a"
            target="_blank"
            href={link.link}
          >
            {link.label}
          </Text>
        ) : (
          <Text
            className={classes.link}
            align={isTablet ? "center" : "left"}
            style={{ cursor: "default", textDecoration: "none" }}
          >
            {link.label}
          </Text>
        )
      ) : (
        <Text
          className={classes.link}
          align={isTablet ? "center" : "left"}
          component="a"
          target="_blank"
          href={link.link}
        >
          {link.label}
        </Text>
      )}
    </Flex>
  ))
  const groupAbout = mockDataAboutFooter.links.map((link, index) => (
    <Text
      key={index}
      className={classes.link}
      align={isTablet ? "center" : "left"}
      onClick={() => {
        if ("/".concat(window.location.href.split("/")[4]) === link.link) {
          window.scrollTo(0, 0)
        } else navigate(link.link)
      }}
    >
      {link.label}
    </Text>
  ))
  const groupServices = mockDataServicesFooter.links.map((link, index) => (
    <Text
      key={index}
      className={classes.link}
      align={isTablet ? "center" : "left"}
      onClick={() => {
        if ("/".concat(window.location.href.split("/")[4]) === link.link) {
          window.scrollTo(0, 0)
        } else navigate(link.link)
      }}
    >
      {link.label}
    </Text>
  ))

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        {isMobile ? (
          <Stack>
            <SimpleGrid cols={2} w="100%" spacing={0}>
              <Stack align={"center"}>
                <Stack align={"center"} spacing={5}>
                  <Text className={classes.title}>
                    {mockDataAboutFooter.title}
                  </Text>
                  {groupAbout}
                </Stack>
                <Image
                  alt="Logo original"
                  src="/logo-original.webp"
                  height={100}
                  width={100}
                  className={classes.logo}
                  onClick={() => {
                    if (window.location.href.split("/")[4] === "") {
                      window.scrollTo(0, 0)
                    } else navigate(RoutesOptions.ROOT)
                  }}
                />
              </Stack>
              <Stack align={"center"} spacing={5}>
                <Text className={classes.title}>
                  {mockDataServicesFooter.title}
                </Text>
                {groupServices}
              </Stack>
            </SimpleGrid>
            <Stack align={"center"} spacing={0}>
              <Text className={classes.title} align="center">
                {mockDataContactFooter.title}
              </Text>
              <SimpleGrid cols={4}>{groupContact}</SimpleGrid>
            </Stack>
          </Stack>
        ) : isTablet ? (
          <Stack align={"center"}>
            <Image
              alt="logo original"
              src="/logo-original.webp"
              height={120}
              width={120}
              className={classes.logo}
              onClick={() => {
                if (window.location.href.split("/")[4] === "") {
                  window.scrollTo(0, 0)
                } else navigate(RoutesOptions.ROOT)
              }}
            />
            <Grid w={"100%"}>
              <Grid.Col span={4}>
                <Stack align={"center"} spacing={5}>
                  <Text className={classes.title}>
                    {mockDataServicesFooter.title}
                  </Text>
                  {groupServices}
                </Stack>
              </Grid.Col>
              <Grid.Col span={4}>
                <Stack align={"center"} spacing={5}>
                  <Text className={classes.title}>
                    {mockDataAboutFooter.title}
                  </Text>
                  {groupAbout}
                </Stack>
              </Grid.Col>
              <Grid.Col span={4}>
                <Stack align={"center"} spacing={0}>
                  <Text className={classes.title} align="center">
                    {mockDataContactFooter.title}
                  </Text>
                  {groupContact}
                </Stack>
              </Grid.Col>
            </Grid>
          </Stack>
        ) : (
          <Grid w={"100%"}>
            <Grid.Col span={2} mr={isLaptopS ? "0.55em" : 0}>
              <Image
                alt="Logo original"
                src="/logo-original.webp"
                height={120}
                width={120}
                className={classes.logo}
                onClick={() => {
                  if (window.location.href.split("/")[4] === "") {
                    window.scrollTo(0, 0)
                  } else navigate(RoutesOptions.ROOT)
                }}
              />
            </Grid.Col>
            <Grid.Col span={isLaptopS ? 2 : 3} mr={isLaptopS ? "2em" : 0}>
              <Stack align={"start"} spacing={5}>
                <Text className={classes.title}>
                  {mockDataAboutFooter.title}
                </Text>
                {groupAbout}
              </Stack>
            </Grid.Col>
            <Grid.Col span={3}>
              <Stack align={"start"} spacing={5}>
                <Text className={classes.title}>
                  {mockDataServicesFooter.title}
                </Text>
                {groupServices}
              </Stack>
            </Grid.Col>
            <Grid.Col span={4}>
              <Stack align={"start"} spacing={0}>
                <Text className={classes.title}>
                  {mockDataContactFooter.title}
                </Text>
                {groupContact}
              </Stack>
            </Grid.Col>
          </Grid>
        )}
      </Container>

      <Container className={classes.afterFooter}>
        <Text color="#D3D3D3" size="0.6em">
          © 2023 PAU CAMELIA
        </Text>
      </Container>
    </footer>
  )
}
